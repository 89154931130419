/* eslint-disable max-len */
import { useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import GlobalContext from "../CommonComponent/context";

export default function ActionModal({
    isOpen,
    setIsOpen,
    mainHeading,
    message,
    ctaButton,
    cancelButton,
    handleClickFunction,
    loader,
    setLoader,
    secondaryMessage,
}) {
    const { setToastmessage } = useContext(GlobalContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleClose = () => {
        if (!loader) {
            setIsOpen(false);
        }
    };

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={handleClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/60" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    className="w-[420px] transform overflow-hidden rounded-lg 
                                bg-white p-8 text-left align-middle shadow-xl transition-all relative"
                                >
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-semibold leading-6 text-[#2F2E38] text-center"
                                    >
                                        {mainHeading}
                                    </Dialog.Title>
                                    <img
                                        src="/Images/close-icon.svg"
                                        alt="icon"
                                        loading="lazy"
                                        className="cursor-pointer absolute top-8 right-8"
                                        style={{
                                            opacity: loader ? 0.5 : 1,
                                            pointerEvents: loader ? "none" : "auto",
                                        }}
                                        onClick={() => !loader && setIsOpen(false)}
                                    />
                                    <div className="pb-4 pt-5">
                                        <p className="text-base text-[#2F2E38] font-normal text-center">
                                            {message}
                                        </p>
                                        <p className="text-base text-[#2F2E38] font-normal text-center">
                                            {secondaryMessage}
                                        </p>
                                    </div>
                                    <div className=" flex justify-center items-center">
                                        <button
                                            type="button"
                                            data-testid="cancel-btn"
                                            className="py-2.5 px-5 border border-[#29275F] 
                                        hover:border-[#4B42A3] text-base text-[#29275F] hover:text-[#4B42A3] 
                                        font-bold rounded-lg flex items-center w-[100px] mr-3.5 justify-center"
                                            onClick={() => setIsOpen(false)}
                                            disabled={loader}
                                        >
                                            {cancelButton}
                                        </button>
                                        <button
                                            disabled={loader}
                                            type="button"
                                            data-testid="confirm-btn"
                                            className="flex justify-center items-center py-2.5 px-5
                                            rounded-lg bg-[#29275F] text-base text-[#FCFBFE] font-bold 
                                            hover:bg-[#4B42A3] disabled:bg-[#B8B8C8]"
                                            onClick={() => handleClickFunction()}
                                        >
                                            {ctaButton}
                                            {loader && (
                                                <>
                                                    <span className="pr-2"></span>
                                                    <RotatingLines
                                                        strokeColor="#FFFFFF"
                                                        strokeWidth="5"
                                                        animationDuration="0.55"
                                                        width="15"
                                                        visible={true}
                                                    />
                                                </>
                                            )}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
