import React from 'react';
import { useNavigate } from 'react-router-dom';


const MenuBox = ({ icon, title, description, path }) => {
    const navigate = useNavigate();
    return (
        <div className='menu-box flex flex-1 flex-grow' onClick={()=>navigate(path)}>
            <div className="bg-white rounded-lg p-6 border border-[#918FA3] menu relative cursor-pointer min-h-[195px] 
            flex flex-col flex-grow">
                <div className="flex items-start justify-center flex-col">
                    {icon}
                    <h3 className="text-base font-normal text-[#2F2E38] pt-2.5 pb-1">{title}</h3>
                </div>
                <p className="text-[#75738C] text-[12px] font-normal">{description}</p>
            </div>
        </div>
    );
};
export default MenuBox;
  
