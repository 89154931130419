import React from 'react';
import { motion } from 'framer-motion';
import { RotatingLines } from 'react-loader-spinner'
import InputWithLabel from "../../../CommonComponent/inputField";
import { useNavigate } from 'react-router-dom';
import AnimatedPage from '../../../CommonComponent/AnimatedPage';


export default function EmailForm({ loader,
    errorState, formInput, handleChange, handleSubmit }) {
    const navigate = useNavigate()

    let animationProps = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { duration: 0.5 } },
    };
    return (
        <AnimatedPage>
            <div className="bg-[#FFFFFF] w-[320px]">
                <h1 className="text-[#2F2E38] text-[32px] pt-6 font-bold">Forgot Password</h1>
                <p className="text-[16px] text-[#2F2E38] font-semibold mt-5 mb-[34px]">
                    Please enter your email address
                </p>
                <form autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
                    <InputWithLabel
                        id="email"
                        label="Email address"
                        type="text"
                        handleChange={handleChange}
                        formInput={formInput}
                        errorState={errorState}
                        showPassword={true}
                    />
                    <button
                        data-testid="send-btn"
                        type="submit"
                        disabled={loader}
                        className="bg-[#29275F] rounded-lg text-[#FCFBFE] font-bold text-[18px] 
                    w-full py-3 hover:bg-[#4B42A3] flex justify-center items-center disabled:bg-[#B8B8C8]"
                    >
                        Request link
                        {loader && (
                            <>
                                <span className='pr-5'></span>
                                <RotatingLines
                                    strokeColor="#FFFFFF"
                                    strokeWidth="5"
                                    animationDuration="0.55"
                                    width="25"
                                    visible={true}
                                />
                            </>
                        )}
                    </button>
                    <div className="flex justify-center pt-[34px]">
                        <button
                            data-testid='back-btn'
                            type="button"
                            onClick={() => navigate('/')}
                            className="text-base text-[#29275F] font-bold hover:text-[#4B42A3]"
                        >
                            Back to Login
                        </button>
                    </div>
                </form>
            </div>
        </AnimatedPage>
    );
}
