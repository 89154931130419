import { fetchAuthSession } from 'aws-amplify/auth';

/**
 * The function `authHeader` generates an authorization header with a bearer token for API requests,
 * handling different environments like localhost.
 * @returns The `authHeader` function returns an object with headers for authorization. If the
 * application is running on localhost, the object will include an additional `Host` header with the
 * value from the `VITE_APP_LOCALHOST_BASE_URL` environment variable. If not on localhost, the object
 * will only include the `Authorization` header with the authentication token.
 */
export async function authHeader() {
    try {
        const authToken = `Bearer ${(await fetchAuthSession()).tokens?.idToken?.toString()}`;
        const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
        let host = window.location.hostname;
        if (isLocalhost) {
            const localTenant = import.meta.env.VITE_APP_LOCALHOST_TENANT_BASE_URL;
            host = import.meta.env.VITE_APP_LOCALHOST_BASE_URL;
            return { headers: { Authorization: authToken, Referer: localTenant }, host: host };
        } else {
            return { headers: { Authorization: authToken }, host: host};
        }
    } catch (err) {
        console.log(err);
    }
}

/**
 * The `Header` function in JavaScript React checks if the application is running on localhost and
 * returns a header object with the host information if true.
 * @returns { headers: { Host: host } }
 */
export async function Header() {
    try {
        const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
        let host = window.location.hostname;
        if (isLocalhost) {
            const localTenant = import.meta.env.VITE_APP_LOCALHOST_TENANT_BASE_URL;
            host = import.meta.env.VITE_APP_LOCALHOST_BASE_URL;
            return { headers: { Referer: host }, host: localTenant };
        } else {
            return { headers: {}, host: host };
        }
    } catch (err) {
        console.log(err);
    }
    return {}; // Return an empty object if not localhost
}