/* eslint-disable max-len */
import React, { useEffect, useState, Fragment } from 'react';
import { motion } from 'framer-motion';

const Toast = ({ message,type, setToastmessage }) => {
    const [showToast, setShowToast] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowToast(false);
            setToastmessage('')
        }, 4000);
        return () => {
            // Update showToast state after a delay to allow animation to complete
            clearTimeout(timer);
        };
    }, []);

    return (
        <motion.div
            initial={{ y: -100, opacity: 0 }}
            animate={{
                y: showToast ? 0 : 100, // Slide from top when appearing, slide to bottom when closing
                opacity: showToast ? 1 : 0,
            }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            className='w-full mx-auto fixed top-10 left-0 right-0 flex justify-center z-50'
        >
            <div className="px-5 success-msg-csv py-3 bg-[#FFFFFF] border border-[#FFFFFF] rounded-[6px] shadow-s1 w-[380px]">
                <div className="flex items-center justify-between">
                    <div className='flex items-center'>
                        <img src={`${type=='error'?'/Images/cancel.svg':'/Images/check_circle.svg'}`} alt="img" />
                        <div>
                            <p className="text-[14px] leading-normal font-normal text-[#2F2E38] px-4">{message}</p>
                        </div>
                    </div>
                    <img src={`${type!=='error'?'/Images/x-success.svg':'/Images/x.svg'}`} alt="img" className='cursor-pointer' onClick={()=>{setShowToast(false);
                        setToastmessage('')}} />
                </div>
            </div>
        </motion.div>
  
    );
};

export default Toast;
