import React, { useState } from "react";

const PasswordInputWithLabel = ({
    label,
    passwordError,
    handlePasswordChange,
    password,
    errorState,
    showPassword,
    ...rest
}) => {
    const [focused, setFocused] = useState(false);
    return (
        <div>
            <label
                className={`absolute transition-all left-0 text-[14px] font-normal ${
                    passwordError !== null || errorState ? "text-[#EA3829]" : "text-[#2F2E38]"
                } duration-300 ${
                    focused || password ? "top-[-18px]" : "top-2"
                }`}
                htmlFor={rest.id}
            >
                {label}
                <span className={"text-[#EA3829]"}>*</span>
            </label>
            <input
                {...rest}
                data-testid={rest.id}
                value={password || ""}
                onChange={(e) => handlePasswordChange(e)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                className={`text-[#2F2E38] border-b font-normal outline-0 w-full py-2.5 h-[45px]
                focus-visible:outline-0 pr-[40px]
                ${showPassword?'text-[14px] leading-5 ':'text-[24px] leading-[14px]'}
                ${label == "Password" && 'pr-[70px]'} ${
            passwordError !== null || errorState
                ? "border-[#EA3829]"
                : focused || password
                    ? "border-[#29275F]"
                    : "border-[#D6D5DD]"
        }`}
            />
            {passwordError!==null && <p className="text-[#EA3829] text-sm font-normal pt-[2px]">
                {passwordError}
            </p>}
        </div>
    );
};

export default PasswordInputWithLabel;
