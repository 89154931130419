import React from 'react';

export const payrolls = [
    { id: 'payPro', label: 'PayPro' },
    { id: 'payroll_partners', label: 'Payroll Partners' },
    { id: 'payroll_prodigy', label: 'Payroll Prodigy' },
    { id: 'cashFlow_payroll', label: 'CashFlow Payroll' },
    { id: 'salary_solutions', label: 'Salary Solutions' },
    { id: 'salaryPro', label: 'SalaryPro' },
    { id: 'payMaster', label: 'PayMaster' },
    { id: 'salarySync', label: 'SalarySync' },
    { id: 'paySystem', label: 'PaySystem' },
];

export const items = [
    { id: 'user_groups', label: 'User Groups' },
    { id: 'units', label: 'Units' },
    { id: 'range', label: 'Range' },
    { id: 'users', label: 'Users' },
    { id: 'coinage', label: 'Coinage' },
    { id: 'pay_periods', label: 'Pay periods' },
    { id: 'third_parties', label: 'Third parties' },
    { id: 'transaction_code', label: 'Transaction code' },
    { id: 'currency', label: 'Currency' },
    { id: 'accounting_periods', label: 'Accounting Periods' },
    { id: 'other_leave', label: 'Other leave' },
    { id: 'company_details', label: 'Company details' },
];

export const organizationalItems = [
    { id: 'cost_centres', label: 'Cost centres' },
    { id: 'discharge_reasons', label: 'Discharge reasons' },
    { id: 'pay_points', label: 'Pay points' },
    { id: 'departments', label: 'Departments' },
    { id: 'nationality', label: 'Nationality' },
    { id: 'positions', label: 'Positions' },
    { id: 'internal_grades', label: 'Internal grades' },
    { id: 'industries', label: 'Industries' },
    { id: 'funeral_policies', label: 'Funeral policies' },
    { id: 'NEC_grades', label: 'NEC grades' },
    { id: 'occupations', label: 'Occupations' },
    { id: 'general_ledger', label: 'General ledger' },
    { id: 'medical_aid_schemes', label: 'Medical aid schemes' },
];
export const functions = [
    { id: 'create_payroll', label: 'Create payroll' },
    { id: 'update_payroll', label: 'Update Payroll' },
    { id: 'activate_employee', label: 'Activate Employee' },
    { id: 'add_employee', label: 'Add Employee' },
    { id: 'update_employee', label: 'Update Employee' },
    { id: 'delete_employee', label: 'Delete Employee' },
    { id: 'discharge_employee', label: 'Discharge Employee' },
    { id: 'suspend_employee', label: 'Suspend Employee' },
];
