/* eslint-disable max-len */
import React from 'react';
import { RotatingLines } from 'react-loader-spinner';
import PasswordInputWithLabel from '../../CommonComponent/passwordFields';
import { motion } from 'framer-motion'
import AnimatedPage from '../../CommonComponent/AnimatedPage';


export default function ChangePasswordForm({clickHandler,
    passwordError,password,confirmPassword,showPassword,showConfirmPassword,showFormate,
    loader,setShowPassword, setShowConfirmPassword, handlePasswordChange, passwordValid, 
    currentPassword, setCurrentPassword, showCurrentPassword, setShowCurrentPassword}) {
        
    let animationProps = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { duration: 0.5 } },
    };
    return (
        <AnimatedPage>
            <div className="rounded-xl custom-width:w-[540px] w-[490px] bg-[#FFFFFF] px-[84px] custom-width:py-[50px] py-[42px] shadow-s2">
                <h1 className="text-[#2F2E38] text-[32px] font-bold">Change Password</h1>
                <p className="text-[16px] text-[#2F2E38] font-semibold mt-4 mb-[50px]">
                    Please enter your new password
                </p>
                <form autoComplete="off">
                    <div className={`relative ${currentPassword?'mb-10':'mb-[30px]'}`}>
                        <PasswordInputWithLabel
                            id="current_password"
                            label="Current password"
                            type={showCurrentPassword ? "text" : "password"}
                            handlePasswordChange={handlePasswordChange}
                            password={currentPassword}
                            passwordError={passwordError=='This field is mandatory' && currentPassword.length>0 || passwordError=='Current and new passwords must differ'||passwordError=='Passwords do not match' ? null : passwordError}
                            errorState={((passwordError=='This field is mandatory' && currentPassword.length==0) || passwordError=='Current and new passwords must differ' ||passwordError==='Incorrect password')?true:false}
                            showPassword={showCurrentPassword}
                        />
                        <img
                            src={
                                currentPassword.length==0 ? 
                                    (!showCurrentPassword?'/Images/closed-eye-inactive.svg':'/Images/eye-open-inactive.svg'):
                                    !showCurrentPassword
                                        ? "/Images/eye-off-active.svg"
                                        : "/Images/eye-open-active.svg"
                            }
                            alt="icon"
                            className="absolute top-[8px] right-2.5 cursor-pointer w-5"
                            onClick={() => setShowCurrentPassword(!showCurrentPassword)} />
                    </div>
                    <div className={`relative ${password?'mb-10':'mb-[30px]'}`}>
                        <PasswordInputWithLabel
                            id="new_password"
                            label="New password"
                            type={showPassword ? "text" : "password"}
                            handlePasswordChange={handlePasswordChange}
                            password={password}
                            passwordError={passwordError=='This field is mandatory' && password.length>0 || passwordError==='Incorrect password' || passwordError=='Passwords do not match' ? null : passwordError}
                            errorState={((passwordError=='This field is mandatory' && password.length==0) || passwordError=='Current and new passwords must differ' || passwordError==='Passwords do not match') ? true : false}
                            showPassword={showPassword}
                        />
                        <img
                            src={password.length==0 ? 
                                (!showPassword?'/Images/closed-eye-inactive.svg':'/Images/eye-open-inactive.svg'):
                                !showPassword
                                    ? "/Images/eye-off-active.svg"
                                    : "/Images/eye-open-active.svg"
                            }
                            alt="icon"
                            className="absolute top-[8px] right-2.5 cursor-pointer w-5"
                            onClick={() => setShowPassword(!showPassword)} />
                        {password.length>0 && showFormate &&
                        <motion.div initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }} 
                            transition={{
                                ease: "linear",
                                duration: 0.5,
                                x: { duration: 1 },
                                delay: 0.1
                            }}
                            className="error-condition pt-3">
                            <p className='text-base text-[#2F2E38] font-normal'>Password must contain:</p>
                            <div className="flex mt-2">
                                <img src={passwordValid.length ?"/Images/check.svg":"/Images/small-x.svg"} 
                                    alt="img" loading="lazy" /> 
                                <p data-testid='Minimum 8 characters' className={`text-base font-normal pl-1.5 
                                ${passwordValid.length ? 'text-[#008F5D]' : 'text-[#EA3829]'}`}>Minimum 8 characters</p>
                            </div>
                            <div className="flex mt-1.5">
                                <img 
                                    src={passwordValid.uppercase ?"/Images/check.svg":"/Images/small-x.svg"} 
                                    alt="img" loading="lazy" /> 
                                <p data-testid='At least one uppercase letter' className={`text-base font-normal pl-1.5 
                                ${passwordValid.uppercase ? 'text-[#008F5D]' : 'text-[#EA3829]'}`}>
                                    At least one uppercase letter</p>
                            </div>
                            <div className="flex mt-1.5">
                                <img 
                                    src={passwordValid.lowercase ?"/Images/check.svg":"/Images/small-x.svg"} 
                                    alt="img" loading="lazy" /> 
                                <p data-testid='At least one lowercase letter' className={`text-base font-normal pl-1.5 
                                ${passwordValid.lowercase ? 'text-[#008F5D]' : 'text-[#EA3829]'}`}>
                                    At least one lowercase letter
                                </p>
                            </div>
                            <div className="flex mt-1.5">
                                <img src={passwordValid.number ?"/Images/check.svg":"/Images/small-x.svg"} 
                                    alt="img" loading="lazy" /> 
                                <p data-testid='At least one number' className={`text-base font-normal pl-1.5 
                                    ${passwordValid.number ? 'text-[#008F5D]' : 'text-[#EA3829]'}`}>
                                    At least one number
                                </p>
                            </div>
                            <div className="flex mt-1.5">
                                <img src={passwordValid.symbol ?"/Images/check.svg":"/Images/small-x.svg"} 
                                    alt="img" loading="lazy" /> 
                                <p data-testid='At least one special character' className={`text-base font-normal pl-1.5 
                                ${passwordValid.symbol ? 'text-[#008F5D]' : 'text-[#EA3829]'}`}>
                                    At least one special character
                                </p>
                            </div>
                        </motion.div>}
                    </div>
                    <div className='relative mb-8'>
                        <PasswordInputWithLabel
                            id="confirm_password"
                            label="Confirm password"
                            type={showConfirmPassword ? "text" : "password"}
                            handlePasswordChange={handlePasswordChange}
                            password={confirmPassword}
                            passwordError={passwordError=='This field is mandatory' && confirmPassword.length>0 || 
                        passwordError==='Incorrect password' || 
                        passwordError=='Current and new passwords must differ'?null:passwordError}
                            errorState={((passwordError=='This field is mandatory' && confirmPassword.length==0) || 
                        passwordError==='Passwords do not match')?true:false}
                            showPassword={showConfirmPassword}
                        />
                        <img
                            src={confirmPassword.length==0 ? 
                                (!showConfirmPassword?'/Images/closed-eye-inactive.svg':'/Images/eye-open-inactive.svg'):
                                !showConfirmPassword
                                    ? "/Images/eye-off-active.svg"
                                    : "/Images/eye-open-active.svg"
                            }
                            alt="icon"
                            className="absolute top-[8px] right-2.5 cursor-pointer w-5"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                    </div>
                    <button
                        onClick={(e)=>clickHandler(e)} loader={loader}
                        data-testid='reset-btn'
                        type="submit"
                        disabled={loader}
                        className="bg-[#29275F] rounded-lg text-[#FCFBFE] font-bold text-[18px] 
                    w-full py-3 hover:bg-[#4B42A3] flex justify-center items-center disabled:bg-[#B8B8C8]"
                    >
                        Update
                        {loader && (
                            <>
                                <span className='pr-5'></span>
                                <RotatingLines
                                    strokeColor="#FFFFFF"
                                    strokeWidth="5"
                                    animationDuration="0.55"
                                    width="25"
                                    visible={true}
                                />
                            </>
                        )}
                    </button>
                </form>
            </div>
        </AnimatedPage>
    );
}
