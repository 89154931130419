/* eslint-disable max-len */
import React, { useState } from 'react'
import Sidebar from './sidebar'
import { Outlet } from 'react-router-dom'

export default function Layout() {
    return (
        <div className={`relative sm:flex m-0 p-0 bg-[#29275F] h-screen`}>
            <Sidebar />
            <div className="w-w2 h-auto mt-5 rounded-tl-lg bg-[#FFFFFF] shadow-s3 relative">
                <Outlet />
            </div>
        </div>
    )
}

