/* eslint-disable max-len */
import React from 'react';
import { motion } from 'framer-motion';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useLocation, useNavigate } from 'react-router-dom';

export default function UserGroupTable({ userGroupList, setIsCloneOpen, setSelectedUserGroup, handleDeleteModal }) {
    const navigate = useNavigate()
    const location = useLocation()

    const animations = {
        initial: { opacity: 0, x: -10 },
        animate: { opacity: 2, x: 0 },
        exit: { opacity: 0, x: 100 }
    }
    return (
        <tbody>
            {userGroupList?.Items?.map((userGroup, index) =>
                <motion.tr
                    key={index}
                    id="list-items" variants={animations}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 1 }}
                    className="border-b border-[#F1F1F4] h-[45px]">
                    <td data-testid={`group_name${index}`} id={`group_name${index}`} className="px-6 text-[14px] leading-5 text-[#2F2E38] font-normal">
                        {userGroup.group_name}
                    </td>
                    <td className=" px-6 text-[14px] leading-5 text-[#2F2E38] font-normal break-all max-w-[180px]">
                        {userGroup.group_description}
                    </td>
                    <td className="px-6 text-[14px] leading-5 text-[#2F2E38] font-normal min-w-[200px]">
                        <div className='flex items-center justify-center'>
                            <div className='mr-5 relative'>
                                <img src='/Images/edit.svg' alt='icon' loading='lazy' data-tooltip-id="edit"
                                    data-testid={`edit-btn-${index}`}
                                    className='cursor-pointer' onClick={()=>navigate(`/user-group/edit/${userGroup.group_id}`, { state: { from: location } })} />
                                <ReactTooltip
                                    id="edit"
                                    place="right"
                                    variant="info"
                                    content="Edit"
                                    style={{
                                        backgroundColor: "#5E5C70",
                                        color: "#F1F1F4",
                                        height: '26px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderRadius: '0',
                                        zIndex: '99'
                                    }}
                                />
                            </div>
                            <div className='mr-5 relative' onClick={() => handleDeleteModal(userGroup)}>
                                <img src='/Images/delete.svg' alt='icon' loading='lazy' data-tooltip-id="delete"
                                    className='cursor-pointer' data-testid={`delete-btn-${index}`}
                                />
                                <ReactTooltip
                                    id="delete"
                                    place="left"
                                    variant="info"
                                    content="Delete"
                                    style={{
                                        backgroundColor: "#5E5C70",
                                        color: "#F1F1F4",
                                        height: '26px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderRadius: '0'
                                    }}
                                />
                            </div>
                            <div className='relative'>
                                <img src='/Images/copy.svg' alt='icon' loading='lazy' data-tooltip-id="clone"
                                    className='cursor-pointer' data-testid={`clone${index}`}
                                    onClick={()=>{setSelectedUserGroup({id:userGroup.group_id,name:userGroup.group_name});
                                        setIsCloneOpen(true)}} />
                                <ReactTooltip
                                    id="clone"
                                    place="left"
                                    variant="info"
                                    content="Clone"
                                    style={{
                                        backgroundColor: "#5E5C70",
                                        color: "#F1F1F4",
                                        height: '26px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderRadius: '0'
                                    }}
                                />
                            </div>
                        </div>
                    </td>
                </motion.tr>
            )}
        </tbody>
    );
}
