import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function TopNav({searchValue,optimizedFn, changeHandler}) {
    const [focused, setFocused] = useState(false);
    const navigate = useNavigate()

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            optimizedFn(e, e.target.value);
        }
    };
    return (
        <div className='flex justify-between'>
            <form autoComplete='off' className={`flex items-center border-b ${focused || searchValue
                ? "border-[#29275F]":"border-[#918FA3]"} w-[320px] pb-2.5`}>
                <label></label>
                <img src='/Images/search.svg' alt='icon' className='mr-2 w-5 h-5' />
                <input type='text' value={searchValue} 
                    onFocus={(e) => {setFocused(true)}}
                    onBlur={() => {setFocused(false)}}                
                    placeholder='Search by Group name' 
                    data-testid="search"
                    id='search-value'
                    className='w-full outline-0 
                    placeholder:text-[#918FA3] text-[#2F2E38] font-normal text-sm' 
                    debounceTimeout={500}
                    onChange={(e) => {
                        e.preventDefault();
                        optimizedFn(e, e.target.value);
                        changeHandler(e);
                    }}
                    onKeyPress={(e)=>handleKeyPress(e)}
                />
            </form>
            <button 
                className='flex justify-center items-center py-2.5 px-5 w-[207px] h-10
                    rounded-lg bg-[#29275F] text-base text-[#FCFBFE] font-bold hover:bg-[#4B42A3] 
                    disabled:bg-[#B8B8C8]' data-testid="add-btn" onClick={()=>navigate("/user-group/add")}
            >
                <img src='/Images/plus.svg' alt='icon' className='mr-[9px]' loading='lazy' />
                Add User Group
            </button>
        </div>
    );
}
