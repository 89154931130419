/* eslint-disable max-len */
import axios from 'axios';
import config from '../Config';
import {authHeader} from '../Redux/helper'
import { Header } from '../Redux/helper';

async function getEnvs(){
    try{
        const headers = await Header();
        const data = await axios.get(` https://${headers.host}/api/user-management/get-tenant-configuration`, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch(error){
        return { error: true, data: error.response };
    }
}

async function sendPasswordResetLink(email){
    try{
        const headers = await Header();
        const data = await axios.post(`https://${headers.host}/api/user-management/forgot-password-send-link/${email}`, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch(error){
        return { error: true, data: error.response };
    }
}


async function VerifyToken(body){
    try{
        const headers = await Header();
        const data = await axios.post(`https://${headers.host}/api/user-management/verify-token`, body, {
            headers: headers.headers, 
        })
        return { error: false, data: data.data }
    }
    catch(error){
        return { error: true, data: error.response.data };
    }
}

async function ForgotPassword(body){
    try{
        const headers = await Header();
        const data = await axios.patch(`https://${headers.host}/api/user-management/forgot-password-verify`, body, {
            headers: headers.headers, 
        })
        return { error: false, data: data.data }
    }
    catch(error){
        return { error: true, data: error.response };
    }
}

async function ResetPassword(body){
    try{
        const headers = await Header();
        const data = await axios.patch(`https://${headers.host}/api/user-management/forgot-password-verify`, body, {
            headers: headers.headers, 
        })
        return { error: false, data: data.data }
    }
    catch(error){
        return { error: true, data: error.response };
    }
}


async function UpdatePassword(body){
    const headers= await authHeader();
    try{
        const data = await axios.patch(`https://${headers.host}/api/user-management/update-password`, body,{
            headers: headers.headers, 
        })
        return { error: false, data: data.data }
    }
    catch(error){
        return { error: true, data: error.response.data };
    }
}

async function AddUserGroup(body){
    const headers = await authHeader();
    try{
        const data = await axios.post(`https://${headers.host}/api/user-management/user-group`, body,{
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch(error){
        return { error: true, data: error.response.data };
    }
}

async function getUserGroup(endpoint){
    const headers = await authHeader();
    try{
        const data = await axios.get(`https://${headers.host}/api/user-management/user-group${endpoint}`,{
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch(error){
        return { error: true, data: error.response.data };
    }
}
async function UpdateUserGroup(id, body){
    const headers = await authHeader();
    try{
        const data = await axios.patch(`https://${headers.host}/api/user-management/user-group/${id}`,body,{
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch(error){
        return { error: true, data: error.response.data };
    }
}
async function ViewUserGroup(id){
    const headers = await authHeader();
    try{
        const data = await axios.get(`https://${headers.host}/api/user-management/user-group/${id}`,{
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch(error){
        return { error: true, data: error.response.data };
    }
}
async function CloneUserGroup(body){
    const headers = await authHeader();
    try{
        const data = await axios.post(`https://${headers.host}/api/user-management/user-group/clone`,body,{
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch(error){
        return { error: true, data: error.response.data };
    }
}


async function deleteUserGroup(id){
    const headers = await authHeader();
    try{
        const data = await axios.delete(`https://${headers.host}/api/user-management/user-group/${id}`,{
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch(error){
        return { error: true, data: error.response };
    }
}

export const dataService = {
    sendPasswordResetLink,
    ForgotPassword,
    VerifyToken,
    UpdatePassword,
    getEnvs,
    AddUserGroup,
    getUserGroup,
    deleteUserGroup,
    UpdateUserGroup,
    ViewUserGroup,
    CloneUserGroup,
}