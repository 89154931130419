/* eslint-disable security/detect-object-injection */
import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InputWithLabel from '../../../CommonComponent/inputField';
import { Tab } from '@headlessui/react';
import NestedCheckboxesAccordion from '../../../CommonComponent/nestedCheckboxAccordion';
import { RotatingLines } from 'react-loader-spinner';
import NestedCheckboxes from '../../../CommonComponent/nestedCheckbox';
import FunctionCheckboxes from '../../../CommonComponent/FunctionCheckboxes';
import { dataService } from '../../../services/dataService';
import GlobalContext from '../../../CommonComponent/context';
import { payrolls, items, organizationalItems, functions } from '../../../CommonComponent/staticValues';
import Loader from '../../../CommonComponent/Loader';

export default function AddUserGroup() {
    const {setToastmessage, setToastErrorMessage}=useContext(GlobalContext)
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [isFullAccess, setIsFullAccess] = useState(false)
    const [formInput, setFormInput] = useState({
        group_name: "",
        description: "",
        payroll: [],
        access_privilege: [],
    });
    const [errorState, setErrorState] = useState({
        group_name: "",
        description: ""    
    });
    const [isEdit,setIsEdit] = useState({})
    const params = useParams()
    const [isEditLoading, setEditLoading] = useState(false)
    const location = useLocation();
    const previousUrl = location.state?.from?.pathname;

    const animationProps = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { duration: 0.5 } },
    };

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ');
    };
    /**
     * The function `handleFetch` fetches user group data and updates state variables accordingly.
     */
    const handleFetch = async()=>{
        const data = await dataService.ViewUserGroup(params.id)
        if(data.data.success_status==true){
            setIsEdit(data.data.data)
            setFormInput({
                group_name: data.data.data.group_name,
                description: data.data.data.group_description,
                payroll: [],
                access_privilege: data.data.data.access_privillage,
            })
            if(data.data.data.access_privillage[0]=='*'){
                setIsFullAccess(true)
            }
            setEditLoading(false)
        }
        else if(data.data.message.includes('Please provide valid Group Id')){
            navigate('/page-not-found')
        }
    } 
    useEffect(()=>{
        if (params.id) {
            setEditLoading(true)
            handleFetch()
        }
    },[])

    /**
 * The handleChange function in JavaScript React ensures that the input value is alphanumeric and
 * updates the form input state accordingly.
 * @returns If the value does not pass the alphanumeric regex test, the function will return early and
 * not update the form input or error state.
 */
    const handleChange = (event) => {
        const { id, value } = event.target;
        setFormInput((prevState) => ({
            ...prevState,
            [id]: value.trimStart().replace(/\s{2,}/g, ' '),
        }));
        setErrorState((prevState) => ({
            ...prevState,
            [id]: "",
        }));
    };
    function validateForm() {
        let isValid = true;
        Object.keys(formInput).forEach((key) => {
            switch (key) {
            case key:
                if (formInput[key] === "") {
                    setErrorState((prevState) => ({
                        ...prevState,
                        [key]: "This field is mandatory",
                    }));
                    isValid = false;
                }
                break;
            default:
                break;
            }
        });
        return isValid;
    }

    const handleGoBack = () => {
        if (previousUrl === '/user-group') {
            navigate(-1); // Navigate to the previous URL in the history stack
        } else {
            navigate('/user-group'); // Navigate to /user-group
        }
    };
    
    /**
     * The function `handleAddUserGroup` is an asynchronous function that handles the addition of a
     * user group by sending a request with form data, displaying appropriate error messages based on
     * the response, and updating the UI accordingly.
     */
    const handleAddUserGroup = async(e)=>{
        e.preventDefault();
        if(validateForm()){
            setLoader(true)
            const body ={
                group_name: formInput.group_name.trim(),
                group_description: formInput.description.trim(),
                access_privillage:formInput.access_privilege
            }
            try{
                let data;
                if(params.id){
                    data = await dataService.UpdateUserGroup(params.id,body)
                    if(data.error==false){
                        setToastmessage('User group updated successfully')
                        // navigate(-1)
                        handleGoBack()
                    }
                    else{
                        if(data.data.message.includes('Group name already exists')){
                            setErrorState((prevState) => ({
                                ...prevState,
                                ["group_name"]: 'Group name already exists'
                            }));
                        }
                        if(data.data.message.includes('Description already exists')){
                            setErrorState((prevState) => ({
                                ...prevState,
                                ["description"]: 'Description already exists'
                            }));
                        }
                        if(data.data.message.includes('select at least one menu option')){
                            setToastErrorMessage(data.data.message)
                        }
                    }
                    setLoader(false)
                }
                else{
                    data = await dataService.AddUserGroup(body)
                    if(data.data.success_status==true){
                        setToastmessage(data.data.message)
                        navigate('/user-group')
                    }
                    else{
                        if(data.data.message.includes('Group name already exists')){
                            setErrorState((prevState) => ({
                                ...prevState,
                                ["group_name"]: 'Group name already exists'
                            }));
                        }
                        if(data.data.message.includes('Description already exists')){
                            setErrorState((prevState) => ({
                                ...prevState,
                                ["description"]: 'Description already exists'
                            }));
                        }
                        if(data.data.message.includes('select at least one menu option')){
                            setToastErrorMessage(data.data.message)
                        }
                    }
                    setLoader(false)
                }
            }catch (error) {
                console.log(error)
            }
        }
    }
    return (
        isEditLoading?
            <div className='h-full flex justify-center items-center bg-[#ceceda] rounded-tl-lg'>
                <Loader/>
            </div>
            :
            <motion.div {...animationProps} className="p-6 relative h-full">
                <p className='pb-6 text-[#2F2E38] text-base font-normal flex items-center'>
                    <p>
                        <span onClick={() => navigate("/users")} className='cursor-pointer'>User Management</span>{' > '} 
                        <span onClick={()=>handleGoBack()} className='cursor-pointer'>User Groups</span>{' > '}
                    </p>
                    <span className='text-[#4B42A3] font-bold pl-1.5'>{params.id?'Update':'Add'} User Group</span>
                </p>
                <h1 className="text-[#2F2E38] text-[32px] font-bold pb-[36px]">{params.id?'Update':'Add'} User Group</h1>
                <form autoComplete='off'>
                    <div className='flex justify-start'>
                        <div className='mr-6 w-[310px]'>
                            <InputWithLabel
                                id="group_name"
                                label="Group name"
                                type="text"
                                handleChange={handleChange}
                                formInput={formInput}
                                errorState={errorState}
                                handlePasswordFocus={null}
                                showPassword={true}
                                maxLength={30}
                            />
                        </div>
                        <div className='custom-width:w-[380px] w-[310px]'>
                            <InputWithLabel
                                id="description"
                                label="Description"
                                type="text"
                                handleChange={handleChange}
                                formInput={formInput}
                                errorState={errorState}
                                handlePasswordFocus={null}
                                showPassword={true}
                                maxLength={30}
                            />
                        </div>
                    </div>
                    <Tab.Group>
                        <Tab.List
                            className="mb-[26px] flex list-none flex-row flex-wrap pl-0 w-full border-b-[0.5px] border-[#D6D5DD]"
                            role="tablist"
                            data-te-nav-ref
                        >
                            <Tab disabled
                                role="presentation"
                                id="payrolls"
                                className={({ selected }) =>
                                    classNames(
                                        "w-fit block text-center p-2.5 text-base",
                                        "font-normal leading-normal hover:isolate hover:border-[#29275F] mr-12 border-b-[2px]",
                                        "focus:isolate",
                                        selected
                                            ? 'bg-[#ffffff] border-[#29275F] text-[#29275F]'
                                            : 'text-[#2F2E38] border-transparent'
                                    )
                                }
                            >
                                Payrolls
                            </Tab>
                            <Tab
                                role="presentation"
                                data-testid="menus"
                                id="menus"
                                className={({ selected }) =>
                                    classNames(
                                        "w-fit block text-center p-2.5 text-base",
                                        "font-normal leading-normal hover:isolate hover:border-[#29275F] mr-12 border-b-[2px]",
                                        "focus:isolate",
                                        selected
                                            ? 'bg-[#ffffff] border-[#29275F] text-[#29275F]'
                                            : 'text-[#2F2E38] border-transparent'
                                    )
                                }
                            >
                                Menus
                            </Tab>
                            <Tab
                                role="presentation"
                                data-testid="functions"
                                id="functions"
                                className={({ selected }) =>
                                    classNames(
                                        "w-fit block text-center p-2.5 text-base",
                                        "font-normal leading-normal hover:isolate hover:border-[#29275F] mr-12 border-b-[2px]",
                                        "focus:isolate",
                                        selected
                                            ? 'bg-[#ffffff] border-[#29275F] text-[#29275F]'
                                            : 'text-[#2F2E38] border-transparent'
                                    )
                                }
                            >
                                Functions
                            </Tab>
                        </Tab.List>
                        <Tab.Panels className="overflow-auto h-h8 customScrollbar pr-5">
                            <Tab.Panel>
                                <NestedCheckboxes
                                    items={payrolls}
                                    setFormInput={setFormInput}
                                    formInput={formInput}
                                />
                            </Tab.Panel>
                            <Tab.Panel>
                                <div className='flex items-center pb-5'>
                                    <button
                                        type="button" onClick={()=>{setIsFullAccess(false);setFormInput(prevState => ({
                                            ...prevState,
                                            access_privilege: [],
                                        }))}}
                                        data-testid="custom_access"
                                        className={`py-2.5 px-5 border border-[#29275F] hover:border-[#4B42A3] text-base 
                                    text-[#29275F] hover:text-[#4B42A3] font-bold rounded-lg flex items-center 
                                    w-fit mr-3.5 justify ${!isFullAccess?'bg-[#EEEDF8]':''}`}>
                                        Custom access
                                    </button>
                                    <button
                                        type="button" data-testid="full_access" 
                                        onClick={()=>{setIsFullAccess(true); setFormInput(prevState => ({
                                            ...prevState,
                                            access_privilege: ["*"],
                                        }))}}
                                        className={`py-2.5 px-5 border border-[#29275F] 
                                hover:border-[#4B42A3] text-base text-[#29275F] hover:text-[#4B42A3] 
                                font-bold rounded-lg flex items-center w-fit justify-center ${isFullAccess?'bg-[#EEEDF8]':''}`}
                                    >
                                        Full access
                                    </button>
                                </div>
                                {!isFullAccess && 
                                <NestedCheckboxesAccordion items={items} setFormInput={setFormInput} formInput={formInput} 
                                    organizationalItems={organizationalItems} params={params} />}
                            </Tab.Panel>
                            <Tab.Panel>
                                <FunctionCheckboxes
                                    items={functions}
                                    setFormInput={setFormInput}
                                    formInput={formInput}
                                    isFullAccess={isFullAccess}
                                />
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                    <button type='button'
                        data-testid={params.id?'update':'aadd'}                    
                        onClick={(e)=>handleAddUserGroup(e)}
                        disabled={loader || (params.id!==undefined && isEdit.group_name==formInput.group_name && 
                            isEdit.group_description==formInput.description && 
                            isEdit.access_privillage==formInput.access_privilege )}
                        className='text-[#FCFBFE] text-base absolute bottom-[24px] left-6 w-[310px]
                                font-bold py-2.5 px-5 rounded-lg bg-[#29275F] hover:bg-[#4B42A3] 
                                flex justify-center items-center disabled:bg-[#B8B8C8]' 
                    >{params.id?'Update':'Add'}
                        {loader && (
                            <>
                                <span className='pr-5'></span>
                                <RotatingLines
                                    strokeColor="#FFFFFF"
                                    strokeWidth="5"
                                    animationDuration="0.55"
                                    width="25"
                                    visible={true}
                                />
                            </>
                        )}
                    </button>            
                </form>
            </motion.div>
    );
}
