import React from 'react';

export default function PageNavigation({handlePrevPage,handleNextPage, prevPageExist,nextPage, currentPage, loading}) {
    return (
        <div className='flex justify-between items-center my-3.5'>
            <div>
                <button type='button' disabled={loading || (!prevPageExist && currentPage==1)} 
                    data-testid="previous"
                    className='flex items-center border border-[#F1F1F4] h-10 justify-center 
                text-[#5E5C70] text-[12px] font-semibold px-3 disabled:text-[#BFBECF]' 
                    onClick={()=>handlePrevPage()}>
                    <div className='pr-3 mr-3 border-r border-[#F1F1F4] h-full flex items-center'>
                        <img src={prevPageExist?'/Images/pagination-arrow-left.svg':
                            '/Images/pagination-arrow-left-inactive.svg'} alt='icon' />
                    </div>
                    Previous
                </button>
            </div>
            <div>
                <button type='button' disabled={loading||!nextPage}
                    data-testid="next"
                    className='flex items-center border border-[#F1F1F4] h-10 justify-center 
                text-[#5E5C70] text-[12px] font-semibold px-3 disabled:text-[#BFBECF]' 
                    onClick={()=>handleNextPage()}>
                    Next
                    <div className='pl-3 ml-3 border-l border-[#F1F1F4] h-full flex items-center'>
                        <img src={nextPage?'/Images/pagination-arrow-right.svg':
                            '/Images/pagination-arrow-right-inactive.svg'} alt='icon' />
                    </div>
                </button>
            </div>
        </div>
    );
}
