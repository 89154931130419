/* eslint-disable security/detect-object-injection */
import React, { Fragment, useContext, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { RotatingLines } from 'react-loader-spinner';
import InputWithLabel from '../../CommonComponent/inputField';
import { dataService } from '../../services/dataService';
import GlobalContext from '../../CommonComponent/context';

export default function CloneUserGroup({isCloneOpen, setIsCloneOpen, selectedUserGroup, getUserGroupList}) {
    const {setToastmessage}=useContext(GlobalContext)
    const [loader, setLoader] = useState(false)
    const [isClone, setIsClone] = useState(false)
    const [formInput, setFormInput] = useState({
        group_name: "",
        description: ""
    });
    const [errorState, setErrorState] = useState({
        group_name: "",
        description: ""    
    });

    /**
     * The function `handleCloseModal` closes a modal, resets form inputs, and sets a state variable to
     * false after a short delay.
     */
    const handleCloseModal = ()=>{
        if(loader==false){
            setIsCloneOpen(false)
            setTimeout(() => {
                setFormInput({
                    group_name: "",
                    description: ""
                })
                setErrorState({
                    group_name: "",
                    description: ""    
                })
                setIsClone(false)    
            }, 200);
        }
    }
    const handleChange = (event) => {
        const { id, value } = event.target;
        setFormInput((prevState) => ({
            ...prevState,
            [id]: value.trimStart().replace(/\s{2,}/g, ' '),
        }));
        setErrorState((prevState) => ({
            ...prevState,
            [id]: "",
        }));
    };
    function validateForm() {
        let isValid = true;
        Object.keys(formInput).forEach((key) => {
            switch (key) {
            case key:
                if (formInput[key] === "") {
                    setErrorState((prevState) => ({
                        ...prevState,
                        [key]: "This field is mandatory",
                    }));
                    isValid = false;
                }
                break;
            default:
                break;
            }
        });
        return isValid;
    }
    /**
     * The handleClone function in JavaScript React is used to clone a user group with validation and
     * error handling.
     */
    const handleClone = async()=>{
        if(isClone==false){
            setIsClone(true)
        }
        else{
            if(validateForm()){
                setLoader(true)
                const body  = {
                    group_name: formInput.group_name,
                    group_description: formInput.description,
                    group_id: selectedUserGroup.id
                }
                const data = await dataService.CloneUserGroup(body)
                if(data.data.success_status==true){
                    setToastmessage(data.data.message)
                    setIsCloneOpen(false)
                    getUserGroupList()
                    setTimeout(() => {
                        setIsClone(false)    
                        setFormInput({
                            group_name: "",
                            description: ""
                        })    
                    }, 300);
                }
                else{
                    if(data.data.message.includes('Group name already exists')){
                        setErrorState((prevState) => ({
                            ...prevState,
                            ["group_name"]: 'Group name already exists'
                        }));
                    }
                    if(data.data.message.includes('Description already exists')){
                        setErrorState((prevState) => ({
                            ...prevState,
                            ["description"]: 'Description already exists'
                        }));
                    }
                }
                setLoader(false)
            }
        }
    }
    return (
        <Transition appear show={isCloneOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" 
                onClose={()=>handleCloseModal()}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/60" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className={`w-[400px] transform overflow-hidden rounded-lg 
                        bg-white py-8 px-6 text-left align-middle shadow-xl 
                        transition-all relative`}>
                                <Dialog.Title
                                    as="h3"
                                    className="text-[18px] font-semibold leading-6 text-[#2F2E38] text-center"
                                >
                                    Clone User Group
                                </Dialog.Title>     
                                <img src='/Images/close-icon.svg' alt='icon' loading='lazy' 
                                    className={`absolute top-8 right-8 ${loader?'opacity-[0.5]':'opacity-100 cursor-pointer'}`}
                                    disabled={loader}
                                    onClick={()=>handleCloseModal()} />
                                {!isClone?<p className='text-[16px] font-normal text-[#2F2E38] text-center py-[16px]'>
                                    Are you sure you want to clone the User group {selectedUserGroup.name}?</p>
                                    :<form autoComplete='off' className='pt-7'>
                                        <InputWithLabel
                                            id="group_name"
                                            label="Group name"
                                            type="text"
                                            handleChange={handleChange}
                                            formInput={formInput}
                                            errorState={errorState}
                                            handlePasswordFocus={null}
                                            showPassword={true}
                                            maxLength={30}
                                        />
                                        <InputWithLabel
                                            id="description"
                                            label="Description"
                                            type="text"
                                            handleChange={handleChange}
                                            formInput={formInput}
                                            errorState={errorState}
                                            handlePasswordFocus={null}
                                            showPassword={true}
                                            maxLength={30}
                                        />
                                    </form>
                                }
                                <div className=" flex justify-center items-center mt-[-6px]">
                                    <button
                                        disabled={loader}
                                        type="button" data-testid="cancel-btn"
                                        className={`py-2.5 px-5 border border-[#29275F] 
                                hover:border-[#4B42A3] text-base text-[#29275F] hover:text-[#4B42A3] 
                                font-bold rounded-lg flex items-center w-[100px] h-10 mr-3.5 justify-center 
                                ${loader?'opacity-[0.5]':'opacity-100 cursor-pointer'}`}
                                        onClick={()=>handleCloseModal()}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        disabled={loader}
                                        type="button" data-testid="clone"
                                        className='flex justify-center items-center py-2.5 px-5
                                    rounded-lg bg-[#29275F] text-base text-[#FCFBFE] font-bold 
                                    hover:bg-[#4B42A3] disabled:bg-[#B8B8C8] h-10'
                                        onClick={()=>handleClone()}
                                    >
                                        Clone
                                        {loader && (
                                            <>
                                                <span className='pr-2'></span>
                                                <RotatingLines
                                                    strokeColor="#FFFFFF"
                                                    strokeWidth="5"
                                                    animationDuration="0.55"
                                                    width="15"
                                                    visible={true}
                                                />
                                            </>
                                        )}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
