import React, { useContext, useEffect, useState } from "react";
import { Suspense, lazy } from "react";
import { Route, Routes, useLocation, useNavigate, } from "react-router-dom";
import { fetchUserAttributes, getCurrentUser } from "aws-amplify/auth";
import { login, logout, setUser, setUserExist } from "../Pages/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import CommonLoader from "../CommonComponent/commonLoader";
import NotFoundPage from "../CommonComponent/404Page";
import Dashboard from "../Pages/Dashboard";
import Layout from "../components/layout";
import ChangePassword from "../Pages/ChangePassword";
import Setup from "../Pages/Setup";
import AddUserGroup from "../Pages/UserManagement/AddUserGroup";
import UserManagement from "../Pages/UserManagement";
import Login from "../Pages/auth/LoginForm";
import ForgotPassword from "../Pages/auth/ForgotPassword";
import ResetPassword from "../Pages/auth/ResetPassword";
import GlobalContext from "../CommonComponent/context";
import ForceResetPassword from "../Pages/auth/ForceResetPassword ";


export default function NavigationRoutes(props, awsConfig) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useSelector((state) => state.auth);
    const { userExist } = auth;
    const { loggedIn } = auth;

    const [pageLoading, setPageLoading] = useState(true);

    /**
     * The function `checkLoggedInUser` asynchronously fetches user attributes, updates the user state
     * and login status based on the response, and handles errors by updating the user state and logging
     * out.
     */
    const checkLoggedInUser = async () => {
        setPageLoading(true)
        try {
            const userAttributes = await fetchUserAttributes();
            dispatch(setUserExist(true))
            setPageLoading(false);
            if (userAttributes["custom:is_reset_password"] !== 'false') {
                dispatch(setUser(userAttributes));
                dispatch(login(true));
            }
        } catch (error) {
            dispatch(setUserExist(false))
            setPageLoading(false);
            dispatch(setUser(''));
        }
    };


    /* The `useEffect` hook in the code snippet is responsible for executing the `checkLoggedInUser`
      function when the `loggedIn` state changes. */
    useEffect(() => {
        if (awsConfig) {
            checkLoggedInUser();
        }
    }, [loggedIn, awsConfig]);

    /* The `useEffect` hook you provided is responsible for handling navigation based on certain
      conditions. Here's a breakdown of what it does: */
    useEffect(() => {
        if (!pageLoading && !loggedIn && (window.location.pathname !== '/forgot-password' &&
            window.location.pathname !== '/reset-password' && window.location.pathname !== '/force-reset-password')) {
            navigate('/');
        }
        else if (!pageLoading && loggedIn && window.location.pathname === '/') {
            navigate('/setup');
        }
        else if(!userExist && window.location.pathname == '/force-reset-password' && loggedIn){
            navigate('/')
        }
        else if(window.location.pathname == '/force-reset-password' && loggedIn){
            navigate('/page-not-found')
        }
    }, [pageLoading]);
    return (
        <Suspense fallback={<div></div>}>
            <Routes location={location} key={location.pathname}>
                {pageLoading
                    ? <Route path="*" element={<CommonLoader />} />
                    :
                    <>
                        {!loggedIn ?
                            <>
                                <Route path="/" element={
                                    <Login />
                                } />
                                <Route path="/forgot-password" element={<ForgotPassword />} />
                            </>
                            :
                            <>
                                <Route element={<Layout {...props} />}>
                                    <Route path="/dashboard" element={<Dashboard />} />
                                    <Route path="/setup" element={<Setup />} />
                                    <Route path="/change-password" element={<ChangePassword />} />
                                    <Route path="/users" element={<UserManagement />} />
                                    <Route path="/user-group" element={<UserManagement />} />
                                    <Route path="/user-group/add" element={<AddUserGroup />} />
                                    <Route path="/user-group/edit/:id" element={<AddUserGroup />} />
                                </Route>
                                <Route path="*" element={<NotFoundPage />} />
                            </>
                        }
                        <Route path="/force-reset-password" element={<ForceResetPassword />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                    </>
                }
            </Routes>
        </Suspense>
    );
}
