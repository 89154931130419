/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { useLocation, useNavigate } from 'react-router';
import LogoutModal from './logoutModal';
import {motion, AnimatePresence} from 'framer-motion'
import { handleSidebarAnimation } from '../../Redux/commonSlice';

export default function Sidebar() {
    const navigate = useNavigate()
    const path = useLocation()
    const userData = useSelector((state) => state.auth)
    let [isOpen, setIsOpen] = useState(false)
    const [isRefreshed, setIsRefreshed] = useState(true);
    const isAnimating=useSelector((state)=>state?.commonData?.isAnimating)
    const dispatch = useDispatch()

    useEffect(() => {
        setIsRefreshed(false);
    }, []);

    let animationProps;
    if(isAnimating){
        animationProps = {
            initial: { opacity: 0, scale: 0.6 },
            animate:{ opacity: 1, scale: 1 },
            transition:{
                duration: 0.3,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01],
            }
        };
    }
    else{
        animationProps = {
            initial: { opacity: 1, scale: 1 },
        }
    }
    return (
        <AnimatePresence>
            <div className='min-w-[256px] bg-[#29275F] pt-6 px-[17px] pb-8 sidebar-container'>
                <nav className='flex flex-col justify-between h-full'>
                    <ul>
                        <li className='pb-6'>
                            
                            <img src='/Images/logo-withName.svg' alt='icon' />
                        </li>
                        <li className={
                            `flex items-center text-sm font-normal mb-1 py-[13px] px-3 cursor-pointer 
                    rounded-lg custom-li side-menu 
                    ${path.pathname.includes('/user') ? 
            'bg-[#FFFFFF] text-[#29275F]' :
            'text-[#EEEDF8] hover:bg-[#4B42A3]'}`} onClick={() => {dispatch(handleSidebarAnimation(false));
                            path.pathname.includes('/user') ? null
                                : navigate('/users')
                        }}>
                            {!path.pathname.includes('/user') ?
                                <motion.img {...animationProps} src='/Images/users-icon.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                <motion.img {...animationProps} src='/Images/users-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                            <motion.img {...animationProps}src='/Images/users-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                            User Management 
                        </li>
                        <li className={
                            `flex items-center text-sm font-normal mb-1 py-[13px] px-3 cursor-pointer 
                    rounded-lg custom-li side-menu 
                    ${path.pathname.includes('/setup') ? 
            'bg-[#FFFFFF] text-[#29275F]' :
            'text-[#EEEDF8] hover:bg-[#4B42A3]'}`} onClick={() => {dispatch(handleSidebarAnimation(false));
                            path.pathname.includes('/setup') ? null
                                : navigate('/setup')
                        }}>
                            {!path.pathname.includes('/setup') ?
                                <motion.img {...animationProps} src='/Images/setup-icon.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                <motion.img {...animationProps} src='/Images/setup-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                            <motion.img {...animationProps}src='/Images/setup-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                            Setup 
                        </li>
                        <li
                            className={
                                `flex items-center text-sm font-normal mb-1 py-[13px] px-3 cursor-pointer 
                    rounded-lg custom-li side-menu 
                    ${path.pathname.includes('/organisational') || path.pathname.includes('/account-types') ? 
            'bg-[#FFFFFF] text-[#29275F]' :
            'text-[#EEEDF8] hover:bg-[#4B42A3]'}`}
                            onClick={() => {
                                path.pathname.includes('/organisational') || path.pathname.includes('/account-types') ? null
                                    : navigate('/organisational')
                            }}>
                            {!path.pathname.includes('/organisational') && !path.pathname.includes('/account-types') ?
                                <motion.img {...animationProps} src='/Images/org-inactive.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                <motion.img {...animationProps} src='/Images/organisational-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                            <motion.img {...animationProps}src='/Images/organisational-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                            Organisational 
                        </li>
                        <li
                            className={`flex items-center text-sm font-normal mb-1 py-[13px] px-3 cursor-pointer 
                    rounded-lg custom-li side-menu
                    ${path.pathname.includes('/employee-master') ? 'bg-[#FFFFFF] text-[#29275F]' : 'text-[#EEEDF8] hover:bg-[#4B42A3]'}`}
                            onClick={() => { path.pathname.includes('/employee-master') ? null : navigate('/employee-master') }}>
                            {!path.pathname.includes('/employee-master') ?
                                <motion.img {...animationProps} src='/Images/Employee-master-icon.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                <motion.img {...animationProps} src='/Images/emp-master-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                            <motion.img {...animationProps}src='/Images/emp-master-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                            Employee Master 
                        </li>
                    </ul>
                    <ul>
                        <li
                            className={`flex items-center text-sm font-normal mb-1 py-2 px-3 cursor-pointer 
                    rounded-lg custom-li side-menu 
                    ${path.pathname == '/change-password' ? 'bg-[#FFFFFF] text-[#29275F]' : 'text-[#EEEDF8] hover:bg-[#4B42A3]'}`}
                            onClick={() => navigate('/change-password')}
                        >
                            {path.pathname !== '/change-password' ?
                                <motion.img {...animationProps} src='/Images/Vector.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                <motion.img {...animationProps} src='/Images/change-password-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                            <motion.img {...animationProps}src='/Images/change-password-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                            Change password
                        </li>
                        <li className='flex items-center text-sm font-normal text-[#EEEDF8] mb-1 py-2 pl-3'>
                            <div className='flex justify-between w-full'>
                                <span className='flex items-center'>
                                    <div className='w-6 h-6 rounded-full px-1.5 pt-[1px] text-[#29275F] text-[10px] font-semibold 
                        bg-[#FFFFFF] flex justify-center items-center mr-2 name-section'>
                                        {userData?.user?.name[0].toUpperCase()}{''}
                                        {userData?.user?.middle_name == undefined ? '' : userData?.user?.middle_name[0].toUpperCase()}
                                    </div>
                                    {userData?.user?.name[0].toUpperCase() + userData.user.name.slice(1)}{' '}         
                                    {userData?.user?.middle_name == undefined ? '' : userData?.user?.middle_name[0]+userData?.user?.middle_name.slice(1)}
                                </span>
                                <div
                                    className='hover:bg-[#4B42A3] custom-li side-menu h-7 w-7 flex 
                            justify-center items-center rounded-lg cursor-pointer'
                                    data-testid="logout-btn"
                                    onClick={() => setIsOpen(true)}
                                >
                                    <motion.img {...animationProps} src='/Images/log-out.svg' alt='icon' loading="lazy" decoding="async" />
                                    <motion.img {...animationProps} src='/Images/log-out-active.svg' alt='icon' loading="lazy" decoding="async" />
                                </div>
                            </div>
                        </li>
                    </ul>
                </nav>
                <LogoutModal isOpen={isOpen} setIsOpen={setIsOpen} />
            </div>
        </AnimatePresence>
    );
}
