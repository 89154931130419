import React, { useContext, useState } from 'react';
import EmailForm from './emailForm';
import { ValidateEmailId } from '../../../CommonComponent/emailValidation';
import { dataService } from '../../../services/dataService';
import GlobalContext from '../../../CommonComponent/context';
import { useNavigate } from 'react-router-dom';
import AccountBlock from '../LoginForm/accountBlock';

export default function ForgotPassword() {
    const { setToastmessage } = useContext(GlobalContext)
    const [formInput, setFormInput] = useState({
        email: ""
    })
    const [errorState, setErrorState] = useState({
        email: "",
    })
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate()
    const [isBlocked, setIsBlocked] = useState('');
    const [isFlip, setIsFlip] = useState(false)
    const [isOtpSent, setIsOtpSent] = useState(false)

    /**
     * The function `validateEmail` checks if the email input is valid and sets error messages
     * accordingly.
     * @returns The `validateEmail` function is returning a boolean value (`true` or `false`) based on
     * the validation of the email input. If the email input is empty or if it is not a valid email
     * address, the function will return `false`, indicating that the email input is not valid.
     * Otherwise, it will return `true`, indicating that the email input is valid.
     */
    function validateEmail() {
        let isValid = true;
        if (formInput.email === "") {
            setErrorState({ ['email']: "This field is mandatory" });
            isValid = false;
        } else if (!ValidateEmailId(formInput.email)) {
            setErrorState({ ['email']: "Invalid email address" });
            isValid = false;
        }
        return isValid;
    }

    /**
     * The handleSubmit function in this JavaScript React code handles form submission, validates an
     * email, sends a password reset link, and displays success or error messages accordingly.
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateEmail()) {
            setLoader(true);
            const response = await dataService.sendPasswordResetLink(formInput.email)
            if (response.error === false) {
                setToastmessage(
                    'Password reset link sent successfully.\nIf your account exists, you will receive a reset link.')
                setLoader(false);
                setFormInput((prevState) => ({
                    ...prevState,
                    ['email']: '',
                }));
                setErrorState((prevState) => ({
                    ...prevState,
                    ['email']: "",
                }));
                navigate('/')
            }
            else if (response.data.data.message.includes('Account has been blocked')) {
                setIsBlocked
                ('Your account has been temporarily blocked due to multiple failed attempts. Please log in after 30 ');
                setIsFlip(true)
            }
            setLoader(false);
        }
    }


    /**
     * The handleChange function updates form input and error state based on user input.
     */
    const handleChange = (event) => {
        const { id, value } = event.target;
        let formValue = value.replace(/\s/g, '');
        setFormInput((prevState) => ({
            ...prevState,
            ['email']: formValue.trim(),
        }));
        setErrorState((prevState) => ({
            ...prevState,
            ['email']: "",
        }));
    };

    return (
        <>
            <div className='w-full flex'>
                <div className="bg-[url(/Images/login-bg.svg)] bg-cover bg-no-repeat h-screen xl:w-[52%] w-1/2">
                    <img src='/Images/logo-withName.svg' alt='icon' className="p-[32px]" />
                </div>
                <div className="w-1/2 flex justify-center items-center">
                    {isBlocked === '' ?
                        <EmailForm
                            handleChange={handleChange}
                            formInput={formInput}
                            loader={loader}
                            errorState={errorState}
                            handleSubmit={handleSubmit}
                        /> :
                        <AccountBlock
                            setIsBlocked={setIsBlocked}
                            isBlocked={isBlocked}
                            setIsOtpSent={setIsOtpSent}
                        />}
                </div>
            </div>
        </>
    );
}
