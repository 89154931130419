/* eslint-disable max-len */
/* eslint-disable security/detect-object-injection */
import React, { useState, useEffect } from 'react';
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";

const NestedCheckboxesAccordion = ({ items, organizationalItems, setFormInput, formInput, params }) => {
    const [openTabs, setOpenTabs] = useState([]);  
    /**
     * The `handleCheckboxChange` function toggles the presence of an item key in the
     * `access_privilege` array of the form input based on the checkbox state.
     */
    const handleCheckboxChange = (accordionType, itemId) => {
        const itemKey = `menu:${accordionType}:${itemId}`;
        const isChecked = formInput.access_privilege.includes(itemKey);
        setFormInput(prevState => ({
            ...prevState,
            access_privilege: isChecked
                ? prevState.access_privilege.filter(item => item !== itemKey)
                : [...prevState.access_privilege, itemKey],
        }));
    };

    const toggleTab = (id) => {
        setOpenTabs(prevOpenTabs => {
            if (prevOpenTabs.includes(id)) {
                return prevOpenTabs.filter(tabId => tabId !== id);
            } else {
                return [...prevOpenTabs, id];
            }
        });
    };
    useEffect(() => {
        if (params.id !== undefined) { // Edit mode
            const newOpenTabs = [];
    
            if (formInput.access_privilege.some(item => item.startsWith('menu:setup:'))) {
                newOpenTabs.push('setup');
            }
    
            if (formInput.access_privilege.some(item => item.startsWith('menu:organisational:'))) {
                newOpenTabs.push('organisational');
            }
            setOpenTabs(newOpenTabs);
        }
    }, []);
    
    /**
     * The function `handleParentCheckboxChange` updates the `access_privilege` array in the form input
     * based on the accordion type and checked status.
     */
    const handleParentCheckboxChange = (accordionType, checked) => {
        if (accordionType === 'employee_master') {
            if (!checked) {
                const retainFunctions = ['functions:create_payroll', 'functions:update_payroll'];
                setFormInput(prevState => ({
                    ...prevState,
                    access_privilege: prevState.access_privilege.filter(
                        item =>
                            retainFunctions.includes(item) ||
                            item.startsWith('menu:setup:') ||
                            item.startsWith('menu:organisational:')
                    ),
                }));
            } else {
                setFormInput(prevState => ({
                    ...prevState,
                    access_privilege: [...prevState.access_privilege, `menu:${accordionType}`],
                }));
            }
        }  else {
            const accordionItems = accordionType === 'setup' ? items : accordionType === 'organisational' ? organizationalItems : [];
            setFormInput(prevState => ({
                ...prevState,
                access_privilege: checked
                    ? [...prevState.access_privilege, ...accordionItems.map(item => `menu:${accordionType}:${item.id}`)]
                    : prevState.access_privilege.filter(item => !item.startsWith(`menu:${accordionType}:`)),
            }));
        }
    };

    function Icon({ id, open }) {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className={`${openTabs.includes(id) ? "rotate-180" : ""} h-5 w-5 transition-transform cursor-pointer`}
                onClick={()=>toggleTab(id)}
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
        );
    }
    const renderAccordion = (id, label, accordionType, accordionItems) => (
        <Accordion open={openTabs.includes(id)} key={id} className="" icon={accordionType !== 'employee_master' ? <Icon id={id} open={openTabs.includes(id)} toggleTab={toggleTab} /> : null}>
            <AccordionHeader className="p-0">
                <div className="checkbox relative">
                    <label htmlFor={`${id}Checkbox`} className="pl-[33px] cursor-pointer text-[#2F2E38] text-base font-normal flex items-center p-2 w-max">
                        {label}
                        <input
                            data-testid={`${label}`}
                            className="checkbox-input custom-checkbox-input"
                            type="checkbox"
                            id={`${id}Checkbox`}
                            checked={
                                accordionType === 'employee_master'
                                    ? formInput.access_privilege.includes(`menu:${accordionType}`)
                                    : accordionItems.every((item) => formInput.access_privilege.includes(`menu:${accordionType}:${item.id}`))
                            }
                            onChange={(e) => {
                                handleParentCheckboxChange(accordionType, e.target.checked, accordionItems);
                                if (e.target.checked && !openTabs.includes(id)) {
                                    toggleTab(id);
                                }
                            }}
                        />
                    </label>
                </div>
                <div onClick={()=>toggleTab(id)} className='w-full h-10'></div>
            </AccordionHeader>
            <AccordionBody>
                <div className="grid grid-cols-3">
                    {accordionItems.map((item, index) => (
                        <div key={item.id} className="checkbox relative p-2">
                            <label htmlFor={`checkbox-${item.id}`} className="pl-[25px] cursor-pointer text-[#2F2E38] text-base font-normal w-fit">
                                {item.label}
                                <input
                                    data-testid={`${item.label}`}
                                    className="checkbox-input custom-checkbox-input"
                                    type="checkbox"
                                    id={`checkbox-${item.id}`}
                                    checked={formInput.access_privilege.includes(`menu:${accordionType}:${item.id}`)}
                                    onChange={() => handleCheckboxChange(accordionType, item.id)}
                                />
                            </label>
                        </div>
                    ))}
                </div>
            </AccordionBody>
        </Accordion>
    );

    return (
        <div>
            {renderAccordion('setup', 'Setup', 'setup', items)}
            {renderAccordion('organisational', 'Organisational', 'organisational', organizationalItems)}
            {renderAccordion('employee_master', 'Employee Master', 'employee_master', [])}
        </div>
    );
};

export default NestedCheckboxesAccordion;