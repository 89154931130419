import React from 'react'
import Lottie from 'lottie-react';
import blockImage from './block-image.json'

export default function CommonLoader() {
    return (
        <div className="flex justify-center items-center h-screen">
            <Lottie animationData={blockImage} loop={true} />
        </div>  
    )
}
