/* eslint-disable security/detect-object-injection */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from "react";
import { signIn, confirmSignIn, fetchUserAttributes } from "aws-amplify/auth";
import LoginForm from "./loginForm";
import { login, logout, setUserExist } from "../authSlice";
import OtpForm from "../OtpForm"
import GlobalContext from "../../../CommonComponent/context";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ValidateEmailId } from "../../../CommonComponent/emailValidation";
import AccountBlock from "./accountBlock";




export default function Login() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { setToastErrorMessage, setToastmessage } = useContext(GlobalContext)
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [isBlocked, setIsBlocked] = useState('');
    const [isFlip, setIsFlip] = useState(false)
    const [capsLockOn, setCapsLockOn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [otpError, setOtpError] = useState("");
    const [otp, setOTP] = useState("");
    const [isOTPResent, setIsOTPResent] = useState(false);
    const [successLogin, setSuccessLogin] = useState(false)
    const [otpLoader, setOtpLoader] = useState(false)
    const [formInput, setFormInput] = useState({
        email: "",
        password: "",
    });
    const [errorState, setErrorState] = useState({
        email: "",
        password: "",
        isRecaptcha: ""
    });
    const [loader, setLoader] = useState(false);
    const [token, setToken] = useState("");
    const [clickCount, setClickCount] = useState(0);






    /* The `useEffect` hook in the provided code snippet is setting up an event listener to detect when
    the Caps Lock key is pressed on the keyboard. Here's a breakdown of what it's doing: */
    // useEffect(() => {
    //     const handleKeyDown = (event) => {
    //         if (event.keyCode === 20) {
    //             setCapsLockOn((prevState) => !prevState);
    //         }
    //         if (event.target.id === "email") {
    //             setCapsLockOn(false)
    //         }
    //     };
    //     const handleInitialCapsLockState = () => {
    //         const isCapsLockOn = navigator.getModifierState
    //             ? navigator.getModifierState('CapsLock')
    //             : false;
    //         setCapsLockOn(isCapsLockOn);

    //     };
    //     const handleKeyDownonPassword = (e) => {
    //         const isCapsLockOn = e.getModifierState
    //             ? e.getModifierState('CapsLock')
    //             : false;
    //         setCapsLockOn(isCapsLockOn);
    //     }
    //     window.addEventListener('keydown', handleKeyDown);
    //     document.getElementById('password').addEventListener('keydown', handleKeyDownonPassword);
    //     handleInitialCapsLockState();

    //     // return () => {
    //     //     window.removeEventListener('keydown', handleKeyDown);
    //     //     document.getElementById('password').removeEventListener('keydown', handleKeyDownonPassword);
    //     // };
    // }, []);

    /**
     * The function `handlePasswordFocus` checks if the focused input field is for email and sets the
     * caps lock state to false.
     */
    const handlePasswordFocus = (e) => {
        const { id, value } = e.target;
        if (id == 'email') {
            setCapsLockOn(false)
        }
    };

    /**
     * The function `validateForm` checks if the email and password fields are filled out correctly in a
     * form and updates error messages accordingly.
     * @returns The `validateForm` function is returning a boolean value `isValid`, which indicates
     * whether the form input is valid or not based on the validation rules specified in the function. If
     * `isValid` is `true`, it means the form input is valid; if `isValid` is `false`, it means there are
     * validation errors in the form input.
     */
    function validateForm() {
        let isValid = true;
        Object.keys(formInput).forEach((key) => {
            switch (key) {
            case "email":
                if (formInput[key] === "") {
                    setErrorState((prevState) => ({
                        ...prevState,
                        [key]: "This field is mandatory",
                    }));
                    isValid = false;
                } else if (!ValidateEmailId(formInput.email)) {
                    setErrorState((prevState) => ({
                        ...prevState,
                        [key]: "Invalid email address",
                    }));
                    isValid = false;
                }
                break;
            case "password":
                if (formInput[key] === "") {
                    setErrorState((prevState) => ({
                        ...prevState,
                        [key]: "This field is mandatory",
                    }));
                    isValid = false;
                }
                break;
            default:
                break;
            }
        });
        return isValid;
    }


    /**
     * The handleChange function updates form input and error state based on user input.
     */
    const handleChange = (event) => {
        const { id, value } = event.target;
        let formValue = value.replace(/\s/g, '')
        setFormInput((prevState) => ({
            ...prevState,
            [id]: formValue.trim(),
        }));
        setErrorState((prevState) => ({
            ...prevState,
            [id]: "",
            isRecaptcha: "",
        }));
    };


    /**
    * The function `handleRecaptchaChange` sets a token value and clears the error state related to
    * Recaptcha.
    */
    const handleRecaptchaChange = (value) => {
        setToken(value);
        setErrorState((prevState) => ({
            ...prevState,
            ['isRecaptcha']: "",
        }))
    };

    /**
     * The function `handlePassCaptcha` increments a click count and logs a message if the count
     * reaches 3.
     */
    const handlePassCaptcha = () => {
        setClickCount(prevCount => {
            const newCount = prevCount + 1;
            if (newCount === 3) {
                setToken('belina');
            }
            return newCount;
        });
    };

    /**
     * The handleSubmit function in this JavaScript React code handles form submission for user sign-in,
     * including validation, error handling, and setting loader states.
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        setOTP("")
        setOtpError("")
        if (validateForm()) {
            if (!token) {
                setErrorState((prevState) => ({
                    ...prevState,
                    ['isRecaptcha']: "reCAPTCHA is mandatory",
                }))
                // alert("Please complete the reCAPTCHA challenge.");
                return; // Prevent form submission if reCAPTCHA token is not available
            }
            setLoader(true);
            try {
                localStorage.setItem('pass', formInput.password)
                const { isSignedIn, nextStep } = await signIn({
                    username: formInput.email,
                    password: formInput.password,
                    options: {
                        authFlowType: "CUSTOM_WITH_SRP",
                    },
                });
                if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE") {
                    setIsOtpSent(true);
                    setToastmessage('OTP sent successfully')
                }
            } catch (error) {
                if ((error.name == 'NotAuthorizedException' && error.message.includes('Incorrect username or password')) || (error.name === "UserLambdaValidationException" && error.message.includes('Incorrect username or password')) || error.name == 'InvalidParameterException') {
                    setToastErrorMessage('Incorrect login details')
                }
                else if (
                    error.name === "UserLambdaValidationException" &&
                    (error.message.includes("User account is disabled") || error.message.includes('temporarily blocked'))
                ) {
                    setIsBlocked(error.message);
                    setIsFlip(true)
                }
                else if (
                    error.name === "UserLambdaValidationException" &&
                    (error.message.includes("Your account has been deactivated. Please contact support for assistance."))
                ) {
                    setToastErrorMessage('Your account has been deactivated. Please contact support for assistance.')
                }
                else if (
                    error.name === "UserLambdaValidationException" &&
                    (error.message.includes("Your temporary password has expired. Please contact support for assistance."))
                ) {
                    setToastErrorMessage('Your temporary password has expired. Please contact support for assistance.')
                }
                else if (
                    error.name === "UserLambdaValidationException" &&
                    (error.message.includes("Please reset your temporary password."))
                ) {
                    navigate('/reset-password')
                }
                else if (error.name === 'NotAuthorizedException' && error.message.includes('Password attempts exceeded')) {
                    setToastErrorMessage(error.message)
                }
            }
            setLoader(false);
        }
    };


    /**
    * The function `submitOtp` is used to handle the submission of an OTP (one-time password) for user
    * authentication in a React application, including error handling and setting appropriate states
    * based on the response.
    */
    const submitOtp = async (e) => {
        e.preventDefault();
        setOtpLoader(true);
        try {
            const output = await confirmSignIn({ challengeResponse: otp });
            if (output.isSignedIn === false) {
                setOtpError("Invalid OTP. Please try again or request a new OTP");
            } else {
                const userAttributes = await fetchUserAttributes();
                if (userAttributes["custom:is_reset_password"] === 'false') {
                    navigate('/force-reset-password')
                    dispatch(login(false));
                    dispatch(setUserExist(true))
                } else {
                    setSuccessLogin(true)
                    setTimeout(() => {
                        dispatch(login(true));
                        setOtpError("");
                        navigate('/setup')
                        setToastmessage('Login Successful')
                    }, 1000);
                }
            }
        } catch (err) {
            if (
                err.name === "UserLambdaValidationException" &&
                err.message.includes(
                    "You have crossed the maximum number of otp attempts."
                )
            ) {
                setOtpError(err.message);
            } else if (
                err.name === "UserLambdaValidationException" &&
                (err.message.includes("User account is disabled") ||
                    err.message.includes("temporarily blocked"))
            ) {
                setIsBlocked(err.message);
                setIsFlip(true)
            }
            else if (err.name == 'NotAuthorizedException' && err.message.includes('Invalid session')) {
                setOtpError("OTP has been expired.Please request new OTP");
            }
        }
        setOtpLoader(false);
    };

    return (
        <>
            <div className="w-full flex">
                <div className="bg-[url(/Images/login-bg.svg)] bg-cover bg-no-repeat h-screen xl:w-[52%] w-[50%]">
                    <img src='/Images/logo-withName.svg' alt='icon' className="p-[32px]" />
                </div>
                <div className="w-1/2 flex justify-center items-center">
                    {isBlocked === '' ?
                        !isOtpSent ? (
                            <>
                                <LoginForm
                                    handleSubmit={handleSubmit}
                                    loader={loader}
                                    setShowPassword={setShowPassword}
                                    capsLockOn={capsLockOn}
                                    errorState={errorState}
                                    formInput={formInput}
                                    handleChange={handleChange}
                                    showPassword={showPassword}
                                    isFlip={isFlip}
                                    handlePasswordFocus={handlePasswordFocus}
                                    handleRecaptchaChange={handleRecaptchaChange}
                                    handlePassCaptcha={handlePassCaptcha}
                                />
                            </>
                        ) : (
                            <OtpForm
                                setOTP={setOTP}
                                setOtpError={setOtpError}
                                otpError={otpError}
                                submitOtp={submitOtp}
                                setIsOTPResent={setIsOTPResent}
                                isOTPResent={isOTPResent}
                                otp={otp}
                                handleSubmit={handleSubmit}
                                loader={otpLoader}
                                successLogin={successLogin}
                            />
                        ) : <AccountBlock
                            setIsBlocked={setIsBlocked}
                            isBlocked={isBlocked}
                            setIsOtpSent={setIsOtpSent}
                        />
                    }
                </div>
            </div>
        </>
    )
}
