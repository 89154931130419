/* eslint-disable max-len */
import React, { useState } from "react";

const InputWithLabel = ({
    label,
    formInput,
    handleChange,
    handlePasswordFocus,
    errorState,
    showPassword,
    maxLength,
    ...rest
}) => {
    const [focused, setFocused] = useState(false);

    return (
        <div className={`relative ${label == "Password" ? "mb-2.5" : rest.id=='group_name'||rest.id=='description' ? 'mb-8' : focused||formInput[rest.id] && !errorState[rest.id]?"mb-[40px]":'mb-7'}`}>
            <label
                className={`absolute transition-all left-0 text-[14px] font-normal ${errorState[rest.id] !== "" ? "text-[#EA3829]" : "text-[#2F2E38]"
                } duration-300 ${focused || formInput[rest.id] ? "top-[-18px]" : "top-[9px]"
                }`}
                htmlFor={rest.id}
            >
                {label}
                <span className="text-[#EA3829]">*</span>
            </label>
            <input
                {...rest}
                maxLength={maxLength}
                data-testid={rest.id}
                value={formInput[rest.id] || ""}
                onChange={(e) => handleChange(e)}
                onFocus={(e) => { setFocused(true); handlePasswordFocus(e) }}
                onBlur={() => { setFocused(false) }}
                className={`text-[#2F2E38] border-b h-[45px] ${showPassword ? 'text-[14px] leading-5 ' : 'text-[24px] leading-[14px]'} font-normal w-full py-2.5 outline-0 focus-visible:outline-0 ${label == "Password" && 'pr-[70px]'} ${errorState[rest.id] !== ""
                    ? "border-[#EA3829]"
                    : focused || formInput[rest.id]
                        ? "border-[#29275F]"
                        : "border-[#D6D5DD]"
                }`}
            />
            <p className="text-[#EA3829] text-sm font-normal pt-[2px]">
                {errorState[rest.id]}
            </p>
        </div>
    );
};

export default InputWithLabel;
