/* eslint-disable max-len */
/* eslint-disable security/detect-object-injection */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import TopNav from './topNav';
import ItemShimmer from '../../CommonComponent/itemShimmer';
import UserGroupTable from './userTable';
import Lottie from 'lottie-react';
import blockImage from '../../CommonComponent/search_not_found.json'
import PageNavigation from '../../CommonComponent/pageNavigation';
import { dataService } from '../../services/dataService';
import { useDispatch, useSelector } from 'react-redux';
import { handlePagination, handleCurrentPage } from '../../Redux/paginationSlice';
import debounce from 'lodash/debounce';
import Debounce from '../../CommonComponent/debounce';
import CloneUserGroup from './clone';
import ActionModal from '../../CommonComponent/actionModal';
import GlobalContext from '../../CommonComponent/context';


export default function UserGroupList() {
    const { setToastmessage, setToastErrorMessage } = useContext(GlobalContext)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams({ limit: 10, sort: 'true', key: 'group_name', order: 'asc' });
    const [searchValue, setSearchValue] = useState(searchParams.get('keyword')!==null? 
        decodeURIComponent(searchParams.get('keyword')):'')
    const [sort, setSort] = useState(`${searchParams.get('key') !== null ? decodeURIComponent(searchParams.get('key')) : ''}+${searchParams.get('order') !== null ? decodeURIComponent(searchParams.get('order')) : ''}`)
    const [loading, setLoading] = useState(true);
    const [userGroupList, setUserGroupList] = useState({})
    const location = useLocation()
    const [nextPage, setNextPage] = useState(true)
    const [prevPageExist, setPrevPage] = useState(false)
    const currentPage = useSelector((state) => state.paginationConfig.currentPage);
    const dispatch = useDispatch();
    const paginationData = useSelector((state) => state.paginationConfig.paginationData);
    const [isCloneOpen,setIsCloneOpen] = useState(false)
    const [selectedUserGroup, setSelectedUserGroup] = useState(
        { 
            id:'',
            name: ''
        })
    let endpoint = `?sort=${encodeURIComponent(sort)}&limit=10`
    const [deleteModal, setDeleteModal] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState({})
    const [spinnerLoader, setSpinnerLoader] = useState(false)

    /* The above code is a React function component that defines a function `getUserGroupList` using
    the `useCallback` hook. This function is an asynchronous function that fetches user group data
    based on certain search parameters. */
    const getUserGroupList = useCallback(async () => {
        setLoading(true)
        if (searchParams.get('keyword') !== null) {
            endpoint += `&search=${searchParams.get('keyword')}`
        }
        if (searchParams.get('LastEvaluatedKey') !== null) {
            endpoint += `&last_element=${searchParams.get('LastEvaluatedKey')}`
        }
        const data = await dataService.getUserGroup(endpoint)
        if (data.data.success_status === true) {
            if (data.data.data.Count == 0) {
                setUserGroupList(data.data.data)
            }
            if (searchValue == '' && data.data.data.Items.length == 0) {
                setNextPage(false)
            }
            else {
                setUserGroupList(data.data.data)
                setNextPage(true)
            }
            if (data.data.data.LastEvaluatedKey === undefined) {
                setNextPage(false)
            }
            else {
                setNextPage(true)
                dispatch(handlePagination({
                    ...paginationData,
                    [currentPage + 1]: data.data.data.LastEvaluatedKey,
                }));
            }
            setTimeout(() => {
                setLoading(false)
            }, 300);
        }
        else {
            if (data.data.message.sort !== undefined && data.data.message?.sort[0]?.includes('Invalid sort value') ||
                data.data.message.includes('Please provide valid input')) {
                navigate('/page-not-found')
            }
        }
    }, [searchParams])

    useEffect(() => {
        getUserGroupList()
    }, [getUserGroupList])

    /**
 * The handleSort function in JavaScript React is used to update sorting parameters based on the key
 * provided.
 */
    const handleSort = (e, key) => {
        setNextPage(true)
        setPrevPage(false)
        dispatch(handleCurrentPage(1))
        let params = Object.fromEntries(searchParams);
        delete params.LastEvaluatedKey;
        if (searchParams.get('sort') === false || searchParams.get('sort') === null) {
            params['sort'] = 'true'
            params['key'] = `${key}`
            params['order'] = `asc`
            setSort(key + '+asc')
        } else {
            if (searchParams.get('key').includes(key)) {
                if (searchParams.get('order') === 'asc') {
                    params['order'] = `desc`
                    setSort(key + '+desc')
                } else {
                    params['order'] = `asc`
                    setSort(key + '+asc')
                }
            }
            else {
                params['key'] = key
                params['order'] = `asc`
                setSort(key + '+asc')
            }
        }
        setSearchParams({ ...params })
    }


    /**
     * The function `handleChange` handles input changes by debouncing the search function
     * `handleSearch` with a delay of 300 milliseconds.
     */

    const handleChange = (e, value) => {
        handleSearch(e, "keyword", value);
    };
    const changeHandler = (e) => {
        setSearchValue(e.target.value);
    };
    const optimizedFn = useCallback(
        Debounce((e, value) => handleChange(e, value)),
        [searchParams]
    );
    const handleSearch = (e, key, value) => {
        e.preventDefault();
        setNextPage(true)
        setPrevPage(false)
        dispatch(handleCurrentPage(1))
        let params = Object.fromEntries(searchParams);
        delete params.LastEvaluatedKey;
        if (key === 'keyword') {
            params[key] = encodeURIComponent(value);
        } else {
            params[key] = value
        }
        if (params['keyword'] === '') delete params.keyword
        setSearchParams({ ...params })

    }

    /**
     * The above functions handle pagination by updating the current page and search parameters for
     * fetching data in a React application.
     */
    const handlePrevPage = async () => {
        setNextPage(true)
        let params = Object.fromEntries(searchParams);
        if (currentPage === 2) {
            setPrevPage(false)
            dispatch(handleCurrentPage(currentPage - 1))
            delete params.LastEvaluatedKey
            setSearchParams({ ...params })
        }
        else {
            dispatch(handleCurrentPage(currentPage - 1))
            setPrevPage(true)
            const lastKey = paginationData[currentPage - 1]
            if (lastKey) {
                params['LastEvaluatedKey'] = lastKey
                setSearchParams({ ...params })
            }
        }
    }

    /**
    * The function `handleNextPage` increments the current page number, sets a flag for the previous
    * page if the current page is greater than or equal to 1, and updates search parameters with
    * pagination data for the next page.
    */
    const handleNextPage = async () => {
        let params = Object.fromEntries(searchParams);
        if (currentPage >= 1) {
            setPrevPage(true)
        }
        dispatch(handleCurrentPage(currentPage + 1))
        params['LastEvaluatedKey'] = paginationData[currentPage + 1]
        setSearchParams({ ...params })
    }

    /**
    * The function `handleDeleteModal` sets the selected user group and opens a delete modal.
    */
    const handleDeleteModal = (user_group) => {
        setSelectedGroup(user_group)
        setDeleteModal(true)
    }

    /**
    * The function `handleDeleteGroup` deletes a user group and displays a success message or an error
    * message accordingly.
    */
    const handleDeleteGroup = async () => {
        setSpinnerLoader(true)
        const response = await dataService.deleteUserGroup(selectedGroup.group_id)
        if (response.error === false) {
            setDeleteModal(false)
            setSpinnerLoader(false)
            setToastmessage('User group deleted successfully')
            getUserGroupList()
        } else {
            setDeleteModal(false)
            setSpinnerLoader(false)
            setToastErrorMessage('Something went wrong')
        }
    }
    return (
        <>
            {userGroupList?.Count !== 0 && <TopNav searchValue={searchValue} optimizedFn={optimizedFn}
                changeHandler={changeHandler} />}
            <div className={`flex table-content mt-[25px] overflow-auto items-start h-h7 customScrollbar`}>
                <table aria-label="Outside Table" className="w-full table-auto overflow-auto customScrollbar pb-2">
                    <thead>
                        <tr className="bg-[#F1F1F4] h-[38px] w-full sticky top-0 left-0 z-10">
                            <th className="px-6 py-3 custom-width:min-w-[320px] min-w-[256px]">
                                <span className="flex items-center text-base leading-5 text-[#2F2E38] font-semibold">
                                    Group name
                                    <button type='button' className="pl-[14px]" data-testid="user_group_name"
                                        onClick={(e) => { handleSort(e, 'group_name') }}
                                    >
                                        {sort.includes('group_name+asc') ?
                                            <img decoding="async" loading="lazy" id="sort"
                                                src="/Images/sort-icon.svg" alt="icon" className='rotate-180' /> :
                                            <img decoding="async" loading="lazy" id="sort"
                                                src="/Images/sort-icon.svg" alt="icon" />}
                                    </button>
                                </span>
                            </th>
                            <th className="px-6 py-3 custom-width:min-w-[320px] min-w-[256px]">
                                <span className="flex items-center text-base leading-5 text-[#2F2E38] font-semibold">
                                    Description
                                </span>
                            </th>
                            <th className="px-6 py-3 min-w-[200px]">
                                <span className="flex items-center justify-center text-base leading-5 
                                text-[#2F2E38] font-semibold">
                                    Actions
                                </span>
                            </th>
                        </tr>
                    </thead>
                    {loading ?
                        <tbody>
                            {Array(10).fill().map((_, index) => (
                                <tr key={index}>
                                    <td><ItemShimmer /></td>
                                    <td><ItemShimmer /></td>
                                    <td><ItemShimmer /></td>
                                </tr>
                            ))}
                        </tbody> :
                        <>
                            <tbody>
                                <td colSpan={3}>
                                    {userGroupList?.Count == 0 ?
                                        <div className='flex flex-col items-center justify-start pt-[38px]'>
                                            <img src='/Images/recordNotFound.svg' alt='icon' loading='lazy' />
                                            <p className='text-[#2F2E38] text-[18px] font-semibold pb-1 pt-[30px]'>
                                                Sorry, there are no user groups available at the moment.</p>
                                            <p className='text-[#2F2E38] text-[18px] font-semibold pb-6'>
                                                To include a new user group, click the button below.</p>
                                            <button type='button' onClick={() => navigate("/user-group/add")}
                                                className='bg-[#29275F] rounded-lg px-5 py-2.5 text-[#FCFBFE] 
                                                            text-base font-bold w-[244px] hover:bg-[#4B42A3] disabled:bg-[#B8B8C8] flex justify-center items-center'
                                                data-testid="add-btn">
                                                <img src='/Images/plus-add-icon.svg' alt='icon' className='mr-2.5' />
                                                Add User Group</button>
                                        </div> :
                                        userGroupList?.Items?.length == 0 &&
                                        <div className="error-message">
                                            <Lottie
                                                animationData={blockImage}
                                                loop={true}
                                                className="w-[240px] mx-auto"
                                            />
                                            <p className='text-[18px] font-semibold text-[#2F2E38] text-center pb-5 pt-12'>
                                                {searchValue !== '' &&
                                                    'We could not find any matches for your search.'}</p>
                                        </div>}
                                </td>
                            </tbody>
                            {userGroupList?.Count != 0 &&
                                <UserGroupTable
                                    userGroupList={userGroupList}
                                    handleDeleteModal={handleDeleteModal}
                                    setIsCloneOpen={setIsCloneOpen} 
                                    setSelectedUserGroup={setSelectedUserGroup}
                                />}
                        </>
                    }
                </table>
            </div>
            {userGroupList?.Items?.length >= 10 &&
                <PageNavigation
                    handlePrevPage={handlePrevPage}
                    handleNextPage={handleNextPage}
                    prevPageExist={prevPageExist}
                    nextPage={nextPage}
                    currentPage={currentPage}
                    loading={loading}
                />
            }
            {deleteModal &&
                <ActionModal
                    isOpen={deleteModal}
                    setIsOpen={setDeleteModal}
                    selectedGroup={selectedGroup}
                    setSelectedGroup={setSelectedGroup}
                    mainHeading={'Delete User Group'}
                    message={`Are you sure you want to delete the User group ‘${selectedGroup && selectedGroup.group_name}’?`}
                    secondaryMessage={'This action cannot be undone.'}
                    ctaButton={'Delete'}
                    cancelButton={'Cancel'}
                    handleClickFunction={handleDeleteGroup}
                    loader={spinnerLoader}
                    setLoader={setSpinnerLoader}

                />

            }

            <CloneUserGroup isCloneOpen={isCloneOpen} setIsCloneOpen={setIsCloneOpen} selectedUserGroup={selectedUserGroup} 
                getUserGroupList={getUserGroupList} />
        </>
    );
}
