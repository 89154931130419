/* eslint-disable security/detect-possible-timing-attacks */
import React, { useContext, useEffect, useState } from 'react';
import { dataService } from '../../../services/dataService';
import InvalidToken from '../../../CommonComponent/invalidToken';
import ConfirmPassword from './confirmPassword';
import { useNavigate } from 'react-router-dom';
import GlobalContext from '../../../CommonComponent/context';
import CommonLoader from '../../../CommonComponent/commonLoader';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { signOut } from 'aws-amplify/auth';


export default function ResetPassword() {
    const { setToastmessage } = useContext(GlobalContext)
    const navigate = useNavigate()
    const [currentPassword, setCurrentPassword] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState(null);
    const [showPassword, setShowPassword] = useState(false)
    const [showCurrentPassword, setShowCurrentPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [passwordValid, setPasswordValid] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        symbol: false,
    });
    const [loader, setLoader] = useState(false)
    const [showFormate, setShowFormate] = useState(true);
    const [isTokenValid, setIsTokenValid] = useState(false)
    const [loading, setLoading] = useState(true)






    /* The `useEffect` hook in the provided code snippet is responsible for updating the `showFormate`
    state based on the values of `password`, `confirmPassword`, and `passwordValid` whenever there
    is a change in these dependencies. */
    useEffect(() => {
        if (password.length === 0) {
            setShowFormate(false);
        }
        else if (passwordValid.length === false || passwordValid.uppercase === false || passwordValid.lowercase === false
            || passwordValid.number === false || passwordValid.symbol === false) {
            setShowFormate(true);
        }
        if (passwordValid.length === true && passwordValid.lowercase === true && passwordValid.number === true &&
            passwordValid.symbol === true && passwordValid.uppercase === true) {
            setTimeout(() => {
                setShowFormate(false);
            }, 1500);
        } if (password.length === 0 && confirmPassword.length === 0) {
            setPasswordError(null);
        }
    }, [password, confirmPassword])

    /**
     * The function `VerifyToken` checks the validity of a token stored in local storage using a data
     * service and updates the state based on the success status of the token verification.
     */
    const VerifyToken = async () => {
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const token = params.get('token');
        const body = {
            token
        }
        const data = await dataService.VerifyToken(body)
        if (data.data.success_status !== false) {
            setIsTokenValid(true)
        }
        else {
            setIsTokenValid(false)
        }
        setLoading(false)
    }

    /**
     * The clickHandler function is used to handle a click event, validate a password, encrypt it, and
     * then confirm the password.
     */
    const clickHandler = async (e) => {
        e.preventDefault();
        setLoader(true)
        if (password.length !== 0 && confirmPassword.length !== 0) {
            let valid = true;
            if (password !== confirmPassword) {
                setPasswordError('Passwords do not match');
                valid = false
                setLoader(false)
            }
            if (valid) {
                const { search } = window.location;
                const params = new URLSearchParams(search);
                const token = params.get('token');
                const resetPasswordBody = {
                    "token": token,
                    "new_password": password,
                    "confirm_password": confirmPassword
                };
                const data = await dataService.ForgotPassword(resetPasswordBody);
                if (data.error === true) {
                    setPasswordError(data.data.message);
                    setLoader(false)
                }
                else {
                    setPassword('')
                    setCurrentPassword('')
                    setConfirmPassword('')
                    setToastmessage('Password reset successfully')
                    setLoader(false)
                    await signOut();
                    navigate('/')

                }
            }
        } else {
            setPasswordError('This field is mandatory');
            setLoader(false)
        }
    }

    /**
     * The function `handlePasswordChange` is used to handle changes in the password input field and
     * update the password state and validation flags accordingly.
     */
    const handlePasswordChange = (e) => {
        const { id, value } = e.target;
        let formValue = value.replace(/\s/g, '');
        setPasswordError(null);
        if (id == 'current_password') {
            setCurrentPassword(formValue.trim());
        }
        else if (id == 'new_password') {
            setPasswordValid({
                length: formValue.length >= 8,
                uppercase: /[A-Z]/.test(formValue),
                lowercase: /[a-z]/.test(formValue),
                number: /\d/.test(formValue),
                symbol: /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-|='"`']/g.test(formValue),
            });
            setPassword(formValue.trim());
        } else {
            setConfirmPassword(formValue.trim());
        }
    }

    useEffect(() => {
        VerifyToken()
    }, [])

    return (
        <>
            {loading ?
                <CommonLoader /> :
                isTokenValid ?
                    <ConfirmPassword
                        clickHandler={clickHandler}
                        passwordError={passwordError} password={password}
                        confirmPassword={confirmPassword} showPassword={showPassword}
                        showConfirmPassword={showConfirmPassword} showFormate={showFormate}
                        loader={loader} setShowPassword={setShowPassword}
                        setShowConfirmPassword={setShowConfirmPassword} handlePasswordChange={handlePasswordChange}
                        passwordValid={passwordValid}
                        showCurrentPassword={showCurrentPassword}
                        setShowCurrentPassword={setShowCurrentPassword}
                        currentPassword={currentPassword}
                        setCurrentPassword={setCurrentPassword}
                    /> :
                    <InvalidToken />}
        </>
    );
}
