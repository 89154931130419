/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion'
import { Tab } from '@headlessui/react'
import { useLocation, useNavigate } from 'react-router-dom';
import UserGroupList from './userGroupList';
import Users from './Users';
import AnimatedPage from '../../CommonComponent/AnimatedPage';
import { useDispatch, useSelector } from 'react-redux';
import { handleCurrentPage, handlePagination } from '../../Redux/paginationSlice';
import { handleSidebarAnimation } from '../../Redux/commonSlice';

export default function UserManagement() {
    const path = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch();
    let paginationData = {};

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    
    useEffect(()=>{
        dispatch(handleSidebarAnimation(false))
    },[])
    return (
        <AnimatedPage>
            <div className="px-6 pt-[72px]">
                <h1 className="text-[#2F2E38] text-[32px] font-bold pb-[24px]">User Management</h1>
                <Tab.Group>
                    <Tab.List
                        className="mb-[26px] flex list-none flex-row flex-wrap pl-0 w-full border-b-[0.5px] border-[#D6D5DD]"
                        role="tablist"
                        data-te-nav-ref>
                        <Tab role="presentation" id="users" onClick={()=>navigate('/users')}
                            className={({ selected }) =>
                                classNames("w-fit block text-center p-2.5 text-base",
                                    "font-normal leading-normal hover:isolate hover:border-[#29275F] mr-12 border-b-[2px]", 
                                    "focus:isolate", 
                                    path.pathname.includes('/users') ? 'bg-[#ffffff] border-[#29275F] text-[#29275F]' : 'text-[#2F2E38] border-transparent')}
                        >Users
                        </Tab>
                        <Tab disabled={path.pathname.includes('/user-group')} role="presentation" data-testid="account-type" id="design" 
                            onClick={()=>{navigate('/user-group');dispatch(handleCurrentPage(1))
                            ;dispatch(handlePagination(paginationData))}}
                            className={({ selected }) =>
                                classNames("w-fit block text-center p-2.5 text-base",
                                    "font-normal leading-normal hover:isolate hover:border-[#29275F] mr-12 border-b-[2px]", 
                                    "focus:isolate", 
                                    path.pathname.includes('/user-group') ? 'bg-[#ffffff] border-[#29275F] text-[#29275F]' : 'text-[#2F2E38] border-transparent')}
                        >User groups
                        </Tab>
                    </Tab.List>
                    <Tab.Panels>
                        <div>
                            {path.pathname.includes('/users')?
                                <Users/>:
                                <UserGroupList/>}
                        </div>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </AnimatedPage>
    );
}
