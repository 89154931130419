/* eslint-disable max-len */
/* eslint-disable security/detect-object-injection */
import React from 'react';

const FunctionCheckboxes = ({ items, setFormInput, formInput, isFullAccess }) => {
    const handleCheckboxChange = (itemId) => {
        const itemKey = `functions:${itemId}`;
        const isChecked = formInput.access_privilege.includes(itemKey);
        setFormInput(prevState => ({
            ...prevState,
            access_privilege: isChecked
                ? prevState.access_privilege.filter(item => item !== itemKey)
                : [...prevState.access_privilege, itemKey],
        }));
    };

    const filteredItems = formInput.access_privilege.includes('menu:employee_master') || isFullAccess
        ? items
        : items.filter(item => item.id === 'create_payroll' || item.id === 'update_payroll');

    return (
        <div className="grid grid-cols-3">
            {filteredItems.length > 0 &&
                filteredItems.map(item => (
                    <div key={item.id} className="checkbox relative p-2">
                        <label htmlFor={`checkbox-${item.id}`} className="pl-[25px] cursor-pointer text-[#2F2E38] text-base font-normal w-fit">
                            {item.label}
                            <input
                                data-testid={`${item.label}`}
                                className="checkbox-input custom-checkbox-input"
                                type="checkbox"
                                id={`checkbox-${item.id}`}
                                checked={formInput.access_privilege.includes(`functions:${item.id}`)}
                                onChange={() => handleCheckboxChange(item.id)}
                            />
                        </label>
                    </div>
                ))}
        </div>
    );
};

export default FunctionCheckboxes;