import { useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import { signOut } from 'aws-amplify/auth';
import { useDispatch } from 'react-redux';
import { logout } from '../../Pages/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner';
import GlobalContext from '../../CommonComponent/context';

export default function LogoutModal({ isOpen, setIsOpen }) {
    const { setToastmessage } = useContext(GlobalContext)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)

    /**
     * The function `handleSignOut` signs the user out, dispatches a logout action, navigates to the
     * home page, and displays a toast message indicating successful logout.
     */
    async function handleSignOut() {
        try {
            setLoader(true)
            await signOut();
            setTimeout(() => {
                setIsOpen(false)
                dispatch(logout())
                navigate('/')
                setLoader(false)
                setToastmessage('Logout Successful')
            }, 1000);
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/60" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-[338px] transform overflow-hidden rounded-lg 
                                bg-white p-8 text-left align-middle shadow-xl transition-all relative">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-semibold leading-6 text-[#2F2E38] text-center"
                                    >
                                        Logout
                                    </Dialog.Title>
                                    <img src='/Images/close-icon.svg' alt='icon' loading='lazy'
                                        className='cursor-pointer absolute top-8 right-8' onClick={() => setIsOpen(false)} />
                                    <div className="pb-4 pt-5">
                                        <p className="text-base text-[#2F2E38] font-normal text-center">
                                            Are you sure you want to Logout?
                                        </p>
                                    </div>

                                    <div className=" flex justify-center items-center">
                                        <button
                                            type="button" data-testid="cancel-btn"
                                            className='py-2.5 px-5 border border-[#29275F] 
                                        hover:border-[#4B42A3] text-base text-[#29275F] hover:text-[#4B42A3] 
                                        font-bold rounded-lg flex items-center w-[100px] mr-3.5 justify-center'
                                            onClick={() => setIsOpen(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            disabled={loader}
                                            type="button" data-testid="confirm-btn"
                                            className='flex justify-center items-center py-2.5 px-5
                                            rounded-lg bg-[#29275F] text-base text-[#FCFBFE] font-bold 
                                            hover:bg-[#4B42A3] disabled:bg-[#B8B8C8]'
                                            onClick={() => handleSignOut()}
                                        >
                                            Logout
                                            {loader && (
                                                <>
                                                    <span className='pr-2'></span>
                                                    <RotatingLines
                                                        strokeColor="#FFFFFF"
                                                        strokeWidth="5"
                                                        animationDuration="0.55"
                                                        width="15"
                                                        visible={true}
                                                    />
                                                </>
                                            )}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
