import React from 'react';
import { motion } from 'framer-motion'
import MenuBox from '../../CommonComponent/MenuBox';

export default function Setup() {
    let animationProps = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { duration: 0.5 } },
    };
    const menuItems = [
        {
            icon: <img src='/Images/time-period-icon.svg' alt='icon' />,
            title: 'Periods',
            description: 'Stores the transactions, separately, for each period',
            path: '/setup/periods'
        },
        {
            icon: <img src='/Images/Units.svg' alt='icon' />,
            title: 'Units',
            description: 'Processes transactions with unit values for any unit type',
            path: '/setup/units'
        },
        {
            icon: <img src='/Images/transaction-code.svg' alt='icon' />,
            title: 'Transaction codes',
            description: 'Processes various payroll transactions for employees.',
            path: '/setup/transaction-codes'
        },
        {
            icon: <img src='/Images/Currencies.svg' alt='icon' />,
            title: 'Currencies',
            description: 'Setup the currencies & exchange rates for the payroll.',
            path: '/setup/currencies'
        },
        {
            icon: <img src='/Images/company.svg' alt='icon' />,
            title: 'Company details',
            description: 'Organisation address for reports & bank details for automated payments.',
            path: '/setup/company'
        },
        {
            icon: <img src='/Images/third-party.svg' alt='icon' />,
            title: 'Third parties',
            description: 'Setup bank & address details for third-party payments',
            path: '/setup/third-party'
        },
        {
            icon: <img src='/Images/other-leaves.svg' alt='icon' />,
            title: 'Other leaves',
            description: 'Setup other leave types & the rules that apply to them.',
            path: '/setup/other-leaves'
        },
        {
            icon: <img src='/Images/Ranges.svg' alt='icon' />,
            title: 'Ranges',
            description: 'Setup age & wage rate ranges for reporting',
            path: '/setup/ranges'
        }
    ]

    return (
        <motion.div {...animationProps} className="px-6 pt-[72px]">
            <h1 className="text-[#2F2E38] text-[32px] font-bold pb-[42px]">Setup menu</h1>
            <div className="grid grid-cols-4 gap-4">
                {menuItems.map((item, index) => (
                    <MenuBox
                        key={index}
                        icon={item.icon}
                        title={item.title}
                        description={item.description}
                        path={item.path}
                    />
                ))}
            </div>
        </motion.div>
    );
}
